import { theme } from 'antd';
import { ThemeConfig } from 'antd/es/config-provider/context';

import { black, darkWhite, gray, lightGray, purple, white } from './colors';

export const themeConfig: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: purple,
    colorBgBase: black,
    colorBgElevated: black,
    colorBgContainerDisabled: black,
    colorBgContainer: black,
    colorBorder: gray,
    colorPrimaryText: white,
    colorText: darkWhite,
    colorTextLabel: lightGray,
    colorTextBase: lightGray,
    colorTextDescription: lightGray,
    colorTextHeading: lightGray,
    colorTextDisabled: gray,
    colorLink: purple,
    colorLinkActive: purple,
    colorLinkHover: purple,
    colorSuccessBg: black,
    colorErrorBg: black,
    colorInfoBg: black,
    colorWarningBg: black,
    colorIcon: purple,
    colorIconHover: purple,
    borderRadius: 4,
    borderRadiusLG: 4,
    borderRadiusSM: 4,
    borderRadiusXS: 4,
  },
};
