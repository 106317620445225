import shallow from 'zustand/shallow';

import { useGenerateImagesStore } from '../../../hooks';
import { cellToOffset } from '../../../utils';
import KonvaImageFromUrl from './KonvaImageFromUrl';

const CanvasGenerationsPreview = () => {
  const { generatedImages, selectedIndex } = useGenerateImagesStore(
    (state) => ({ generatedImages: state.generatedImages, selectedIndex: state.selectedIndex }),
    shallow
  );

  return (
    <>
      {generatedImages.map((cell, i: number) => (
        <KonvaImageFromUrl
          key={cell.url}
          src={cell.url}
          listening={false}
          offset={cellToOffset(cell)}
          visible={i === selectedIndex}
        />
      ))}
    </>
  );
};

export default CanvasGenerationsPreview;
