import { Stage } from 'konva/lib/Stage';
import { Vector2d } from 'konva/lib/types';

import { CELL_SIZE_PX } from '../constants';
import { getScaledCursorPosition } from './getScaledCursorPosition';

export function getCellFromCursorPosition(stage: Stage, gridConfig: any) {
  return getCellFromScaledPosition(getScaledCursorPosition(stage), gridConfig);
}

export function getCellFromScaledPosition(pos: Vector2d | undefined, gridConfig: any): Vector2d | undefined {
  if (!pos) {
    return;
  }

  const cellX = Math.floor(pos.x / CELL_SIZE_PX);
  const cellY = Math.floor(pos.y / CELL_SIZE_PX);

  if (!isWithinRange(cellX, 0, gridConfig.width - 1) || !isWithinRange(cellY, 0, gridConfig.height - 1)) {
    return;
  }

  return { x: cellX, y: cellY };
}

function isWithinRange(val: number, min: number, max: number): boolean {
  return val >= min && val <= max;
}
