import Konva from 'konva';
import { IRect, Vector2d } from 'konva/lib/types';

import { GENERATED_IMAGE_SIZE_PX, CELL_OVERLAP_SIZE_PX } from '../constants';
import { IGridCellResult, MaskLine } from '../hooks/index';
import { ICellImage } from '../types';
import { cellToCoordinates } from './cellToCoordinates';
import { cellToOffset } from './cellToOffset';

/**
 * Generating a mask image from InpaintingCanvas.tsx is not as simple
 * as calling toDataURL() on the canvas, because the mask may be represented
 * by colored lines or transparency, or the user may have inverted the mask
 * display.
 *
 * So we need to regenerate the mask image by creating an offscreen canvas,
 * drawing the mask and compositing everything correctly to output a valid
 * mask image.
 */
export async function generateSampleFrame({
  cells,
  lines,
  selectedCell,
  overlapSampling,
}: {
  cells: IGridCellResult[];
  lines: MaskLine[];
  selectedCell: Vector2d;
  overlapSampling: boolean;
}): Promise<{ frameDataURL: string }> {
  const { stage, layer } = createStage(selectedCell);
  const { selectedImage, surroundingImages } = findRelevantImages(selectedCell, cells, overlapSampling);
  const [loadedSelectedImage, ...loadedSurroundingImages] = await loadImages([selectedImage, ...surroundingImages]);

  // paint the selected image
  if (loadedSelectedImage) {
    layer.add(
      new Konva.Image({
        image: loadedSelectedImage.elem,
        offset: cellToOffset(loadedSelectedImage),
      })
    );
    layer.draw();
  }

  // paint the mask
  lines.forEach((line) =>
    layer.add(
      new Konva.Line({
        points: line.points,
        stroke: 'black',
        strokeWidth: line.strokeWidth * 2,
        tension: 0,
        lineCap: 'round',
        lineJoin: 'round',
        shadowForStrokeEnabled: false,
        globalCompositeOperation: 'destination-out',
      })
    )
  );
  layer.draw();

  // paint the surroundings
  loadedSurroundingImages.forEach((image) => {
    layer.add(
      new Konva.Image({
        image: image.elem,
        offset: cellToOffset(image),
      })
    );
  });

  layer.draw();

  const { x, y } = cellToCoordinates(selectedCell);

  const frameDataURL = stage.toDataURL({
    x: x - CELL_OVERLAP_SIZE_PX,
    y: y - CELL_OVERLAP_SIZE_PX,
    width: GENERATED_IMAGE_SIZE_PX,
    height: GENERATED_IMAGE_SIZE_PX,
  });

  return { frameDataURL };
}

function findRelevantImages(
  selectedCell: Vector2d,
  cells: IGridCellResult[],
  overlapSampling: boolean
): { selectedImage: ICellImage; surroundingImages: ICellImage[] } {
  const desiredCells: Vector2d[] = [];
  let selectedImage: ICellImage;
  const surroundingImages: ICellImage[] = [];

  if (overlapSampling) {
    for (let x = selectedCell.x - 1; x <= selectedCell.x + 1; x++) {
      for (let y = selectedCell.y - 1; y <= selectedCell.y + 1; y++) {
        desiredCells.push({ x, y });
      }
    }
  } else {
    desiredCells.push(selectedCell);
  }

  cells.forEach((cell) => {
    const isDesiredCell = !!desiredCells.find((desired) => desired.x === cell.x && desired.y === cell.y);
    if (cell.url && isDesiredCell) {
      if (cell.x === selectedCell.x && cell.y === selectedCell.y) {
        selectedImage = cell as ICellImage;
      } else {
        surroundingImages.push(cell as ICellImage);
      }
    }
  });

  return { selectedImage: selectedImage!, surroundingImages };
}

async function loadImages(images: ICellImage[]): Promise<(ICellImage & { elem: HTMLImageElement })[]> {
  const res = await Promise.all(
    images.map(
      (image) =>
        new Promise((resolve, reject) => {
          if (image) {
            const elem = new Image();
            elem.crossOrigin = 'anonymous';
            elem.src = image.url;
            elem.onload = () => {
              resolve({ ...image, elem });
            };
            elem.onerror = () => reject('error!');
          } else {
            resolve(null as any);
          }
        })
    )
  );
  // Konva.Image.fromURL(url, img => {
  //   layer.add(img);
  // });

  return res as (ICellImage & { elem: HTMLImageElement })[];
}

function createStage(selectedCell: Vector2d): {
  stage: Konva.Stage;
  layer: Konva.Layer;
} {
  const offscreenContainer = document.createElement('div');

  const stage = new Konva.Stage({
    container: offscreenContainer,
    width: (selectedCell.x + 1) * GENERATED_IMAGE_SIZE_PX,
    height: (selectedCell.y + 1) * GENERATED_IMAGE_SIZE_PX,
  });

  const layer = new Konva.Layer();

  stage.add(layer);

  offscreenContainer.remove();

  return { stage, layer };
}

/**
 * Check if the bounding box region has only fully transparent pixels.
 */
export const checkIsRegionEmpty = (stage: Konva.Stage, boundingBox: IRect): boolean => {
  const imageData = stage
    .toCanvas()
    .getContext('2d')
    ?.getImageData(boundingBox.x, boundingBox.y, boundingBox.width, boundingBox.height);

  if (!imageData) {
    throw new Error('Unable to get image data from generated canvas');
  }

  const pixelBuffer = new Uint32Array(imageData.data.buffer);

  return !pixelBuffer.some((color) => color !== 0);
};
