import { useMemo } from 'react';
import { Rect } from 'react-konva';
import { useAccount } from 'wagmi';
import shallow from 'zustand/shallow';

import { CELL_SIZE_PX } from '../../../constants';
import { useCanvasStore, useGridStore } from '../../../hooks';
import { cellToOffset } from '../../../utils';

const CanvasOwnCellsHighlight = () => {
  const { address } = useAccount();
  const { mode } = useCanvasStore((state) => ({ mode: state.mode }), shallow);
  const { cells, highlightOwnCells } = useGridStore(
    (state) => ({ cells: state.cells, highlightOwnCells: state.highlightOwnCells }),
    shallow
  );
  const ownCells = useMemo(() => {
    if (!address) return [];
    const addressLower = (address || '').toLowerCase();
    return cells.filter((cell) => cell.owner && (cell.owner || '').toLowerCase() === addressLower);
    // return cells.filter((cell) => cell);
  }, [address, cells]);

  if (mode !== 'view' || !highlightOwnCells) {
    return null;
  }

  return (
    <>
      {ownCells.map((cell) => (
        <Rect
          key={cell.url}
          width={CELL_SIZE_PX}
          height={CELL_SIZE_PX}
          fill={'rgba(0,255,0,0.3)'}
          offset={cellToOffset(cell)}
        />
      ))}
    </>
  );
};

export default CanvasOwnCellsHighlight;
