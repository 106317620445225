interface ISimpleRect {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export function areRectsIntersecting(a: ISimpleRect, b: ISimpleRect): boolean {
  return a.left <= b.right && b.left <= a.right && a.top <= b.bottom && b.top <= a.bottom;
}
