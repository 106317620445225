import create from 'zustand';
import clamp from 'lodash/clamp';
import { Vector2d } from 'konva/lib/types';
import { Stage } from 'konva/lib/Stage';
import { MutableRefObject } from 'react';

import { CANVAS_SCALE_FACTOR, MAX_CANVAS_SCALE, MIN_CANVAS_SCALE } from '../constants';
import { useCanvasEditorStoreMethods } from './useCanvasEditor';

export type CanvasMode = 'view' | 'edit' | 'pickGeneration';

interface ICanvasStoreState {
  mode: CanvasMode;
  scale: number;
  cursorPosition: Vector2d | null;
  stagePosition: Vector2d | null;
  stageRef: MutableRefObject<Stage | null>;
}

interface ICanvasStoreMethods {
  setMode: (mode: CanvasMode) => void;
  zoomIn: () => number;
  zoomOut: () => number;
  setScale: (scale: number) => number;
  setCursorPosition: (position: Vector2d | null) => void;
  setStagePosition: (position: Vector2d | null) => void;
}

interface ICanvasHook extends ICanvasStoreState, ICanvasStoreMethods {}

const defaultState: ICanvasStoreState = {
  mode: 'view',
  scale: (MIN_CANVAS_SCALE + MAX_CANVAS_SCALE) / 2,
  cursorPosition: null,
  stagePosition: null,
  stageRef: { current: null },
};

export const useCanvasStore = create<ICanvasStoreState>(() => defaultState);

export const useCanvasStoreMethods: ICanvasStoreMethods = {
  setMode: (mode: CanvasMode) => {
    useCanvasEditorStoreMethods.setTool(mode === 'edit' ? 'eraser' : 'drag');
    if (mode === 'view') {
      useCanvasEditorStoreMethods.forgetLines();
    }
    useCanvasStore.setState({ mode });
  },
  setScale: (scale: number) => {
    const newScale = clamp(scale, MIN_CANVAS_SCALE, MAX_CANVAS_SCALE);
    useCanvasStore.setState({ scale: newScale });

    return newScale;
  },
  zoomIn: () => {
    const { scale } = useCanvasStore.getState();
    return useCanvasStoreMethods.setScale(scale / CANVAS_SCALE_FACTOR);
  },
  zoomOut: () => {
    const { scale } = useCanvasStore.getState();
    return useCanvasStoreMethods.setScale(scale * CANVAS_SCALE_FACTOR);
  },
  setCursorPosition: (position: Vector2d | null) => {
    useCanvasStore.setState({ cursorPosition: position });
  },
  setStagePosition: (position: Vector2d | null) => {
    useCanvasStore.setState({ stagePosition: position });
  },
};

export function useCanvas(): ICanvasHook {
  const storeState = useCanvasStore();

  return { ...storeState, ...useCanvasStoreMethods };
}
