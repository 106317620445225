import Konva from 'konva';
import { useEffect, useMemo, useRef } from 'react';
import { Circle, Rect } from 'react-konva';
import shallow from 'zustand/shallow';

import { CELL_SIZE_PX } from '../../../constants';
import { useCanvasStore, useGenerateImagesStore, useGridStore } from '../../../hooks';

const borderSize = 32;
const animationSpeed = 0.045;

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d')!;

const CanvasCellBoundingBox = () => {
  const { mode } = useCanvasStore((state) => ({ mode: state.mode }), shallow);
  const { selectedCell } = useGridStore((state) => ({ selectedCell: state.selectedCell }), shallow);
  const { loading } = useGenerateImagesStore((state) => ({ loading: state.loading }), shallow);
  const shapeRef = useRef<Konva.Rect>(null);
  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  useEffect(() => {
    if (!shapeRef.current) return;

    let clock = 0;
    drawGradient();

    if (loadingRef.current) {
      animateGradient();
    }

    function animateGradient() {
      clock += animationSpeed;

      drawGradient();

      if (loadingRef.current) {
        window.requestAnimationFrame(animateGradient);
      }
    }

    function drawGradient() {
      if (!shapeRef.current) return;

      const rect = shapeRef.current;

      const currentPos = clock % 360;
      const gradient = ctx.createConicGradient(
        -90 + currentPos,
        CELL_SIZE_PX / 2 + borderSize,
        CELL_SIZE_PX / 2 + borderSize
      );

      gradient.addColorStop(0, '#dc435a');
      gradient.addColorStop(0.125, '#4daf50');
      gradient.addColorStop(0.45, '#fda002');
      gradient.addColorStop(0.7, '#03bcc8');
      gradient.addColorStop(0.9, '#dc435a');

      rect.fill(gradient as any);
    }
    // shapeRef.current is necessary, otherwise the box will not be visible on subsequent cell selections
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shapeRef.current, loading]);

  if (!selectedCell) return null;

  return (
    <>
      <Rect
        x={selectedCell.x * CELL_SIZE_PX - borderSize}
        y={selectedCell.y * CELL_SIZE_PX - borderSize}
        width={CELL_SIZE_PX + 2 * borderSize}
        height={CELL_SIZE_PX + 2 * borderSize}
        ref={shapeRef}
        listening={false}
      />
      <Rect
        x={selectedCell.x * CELL_SIZE_PX}
        y={selectedCell.y * CELL_SIZE_PX}
        width={CELL_SIZE_PX}
        height={CELL_SIZE_PX}
        fill={'black'}
        listening={false}
        globalCompositeOperation={'destination-out'}
      />
    </>
  );
};

export default CanvasCellBoundingBox;
