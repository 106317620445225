export const GENERATED_IMAGE_SIZE_PX = 1024;
export const CELL_OVERLAP_SIZE_PX = 64;
export const CELL_SIZE_PX = GENERATED_IMAGE_SIZE_PX - 2 * CELL_OVERLAP_SIZE_PX;

export const HEADER_HEIGHT = 72;
export const SIDEPANEL_WIDTH = 320;

export const CANVAS_SCALE_FACTOR = 1.1;
export const MIN_CANVAS_SCALE = 0.05;
export const MAX_CANVAS_SCALE = 1;
