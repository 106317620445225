import { Button, Tooltip } from 'antd';
import { useHotkeys } from 'react-hotkeys-hook';
import { FaHighlighter } from 'react-icons/fa';
import styled from 'styled-components';
import shallow from 'zustand/shallow';
import cn from 'classnames';

import { useAuthStore, useCanvasStore, useGridStore, useGridStoreMethods } from '../../hooks';

export default function HighlightOwnCells() {
  const { status } = useAuthStore((state) => ({ status: state.status }), shallow);
  const { mode } = useCanvasStore((state) => ({ mode: state.mode }), shallow);
  const { highlightOwnCells } = useGridStore((state) => ({ highlightOwnCells: state.highlightOwnCells }), shallow);

  const handleToggleHighlight = () => useGridStoreMethods.toggleHighlightOwnCells();

  // Hotkeys
  // Set tool to eraser
  useHotkeys(
    'h',
    (e: KeyboardEvent) => {
      e.preventDefault();
      handleToggleHighlight();
    },
    []
  );

  const open = mode === 'view' && status === 'authenticated';

  return (
    <Container className={cn({ open })}>
      <Tooltip placement="right" title="Highlight Cells You Own (H)">
        <Button
          type={highlightOwnCells ? 'primary' : 'default'}
          icon={<FaHighlighter />}
          size="large"
          onClick={handleToggleHighlight}
        />
      </Tooltip>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 32px;
  transform: translateX(-100%);
  transition: transform 0.2s ease;

  &.open {
    transform: translateX(0);
  }
`;
