import { Stage } from 'konva/lib/Stage';
import { Vector2d } from 'konva/lib/types';

import { CELL_SIZE_PX, HEADER_HEIGHT, SIDEPANEL_WIDTH } from '../constants';
import { calcFocusOnCoords, focusOnCoords, Spacing } from './focusOnCoords';

const cellPadding = CELL_SIZE_PX / 2;
const extraPadding = 200;

const sidePanelOpenMargin: Spacing = [0, HEADER_HEIGHT, SIDEPANEL_WIDTH, 0];

export function focusOnCell({
  stage,
  cell,
  padding = extraPadding,
  margin = sidePanelOpenMargin,
}: {
  stage: Stage;
  cell: Vector2d;
  padding?: number;
  margin?: number | Spacing;
}): { scale: number } {
  const centerX = CELL_SIZE_PX * cell.x + CELL_SIZE_PX / 2;
  const centerY = CELL_SIZE_PX * cell.y + CELL_SIZE_PX / 2;

  return focusOnCoords({
    stage,
    coords: { x: centerX, y: centerY },
    padding: cellPadding + padding,
    margin,
  });
}

export function calcFocusOnCell({
  cell,
  padding = extraPadding,
  margin = sidePanelOpenMargin,
}: {
  cell: Vector2d;
  padding?: number;
  margin?: number | Spacing;
}): { scale: number; position: Vector2d } {
  const centerX = CELL_SIZE_PX * cell.x + CELL_SIZE_PX / 2;
  const centerY = CELL_SIZE_PX * cell.y + CELL_SIZE_PX / 2;

  return calcFocusOnCoords({
    coords: { x: centerX, y: centerY },
    padding: cellPadding + padding,
    margin,
  });
}
