import { Button, Tooltip } from 'antd';
import { useHotkeys } from 'react-hotkeys-hook';
import { FaRegHandPaper } from 'react-icons/fa';
import shallow from 'zustand/shallow';

import { useCanvasEditorStore, useCanvasEditorStoreMethods } from '../../../hooks';

export default function CanvasEditorDragControl() {
  const { tool } = useCanvasEditorStore((state) => ({ tool: state.tool }), shallow);

  const handleSelectDrag = () => useCanvasEditorStoreMethods.setTool('drag');

  // Hotkeys
  // Set tool to eraser
  useHotkeys(
    'd',
    (e: KeyboardEvent) => {
      e.preventDefault();
      handleSelectDrag();
    },
    []
  );

  return (
    <Tooltip placement="right" title="Drag (D)">
      <Button
        type={tool === 'drag' ? 'primary' : 'default'}
        icon={<FaRegHandPaper />}
        size="large"
        onClick={handleSelectDrag}
      />
    </Tooltip>
  );
}
