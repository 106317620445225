import { Button, Popover, Slider, InputNumber } from 'antd';
import { useHotkeys } from 'react-hotkeys-hook';
import { FaEraser } from 'react-icons/fa';
import styled from 'styled-components';
import shallow from 'zustand/shallow';

import { useCanvasEditorStore, useCanvasEditorStoreMethods } from '../../../hooks';

export default function CanvasEditorEraserControl() {
  const { tool, brushSize, minBrushSize, maxBrushSize } = useCanvasEditorStore(
    (state) => ({
      tool: state.tool,
      brushSize: state.brushSize,
      minBrushSize: state.minBrushSize,
      maxBrushSize: state.maxBrushSize,
    }),
    shallow
  );

  const handleSelectEraserTool = () => useCanvasEditorStoreMethods.setTool('eraser');

  const handleChangeBrushSize = (v: number | null) => {
    if (v) {
      useCanvasEditorStoreMethods.setBrushSize(v);
    }
  };

  // Decrease brush size
  useHotkeys(
    '[',
    (e: KeyboardEvent) => {
      e.preventDefault();
      if (brushSize - 5 > 0) {
        handleChangeBrushSize(brushSize - 5);
      } else {
        handleChangeBrushSize(1);
      }
    },
    {
      // enabled: activeTabName === 'inpainting' && shouldShowMask,
    },
    [brushSize]
  );

  // Increase brush size
  useHotkeys(
    ']',
    (e: KeyboardEvent) => {
      e.preventDefault();
      handleChangeBrushSize(brushSize + 5);
    },
    {
      // enabled: activeTabName === 'inpainting' && shouldShowMask,
    },
    [brushSize]
  );

  // Hotkeys
  // Set tool to eraser
  useHotkeys(
    'e',
    (e: KeyboardEvent) => {
      e.preventDefault();
      // if (activeTabName !== 'inpainting' || !shouldShowMask) return;
      handleSelectEraserTool();
    },
    {
      // enabled: activeTabName === 'inpainting' && shouldShowMask,
    },
    []
  );

  return (
    <Popover
      content={
        <PopoverContent>
          <Slider min={minBrushSize} max={maxBrushSize} step={1} value={brushSize} onChange={handleChangeBrushSize} />
          <InputNumber
            min={minBrushSize}
            max={maxBrushSize}
            step={1}
            value={brushSize}
            onChange={handleChangeBrushSize}
          />
        </PopoverContent>
      }
      title="Eraser (E)"
      trigger="hover"
      placement="right"
    >
      <Button
        icon={<FaEraser />}
        type={tool === 'eraser' ? 'primary' : 'default'}
        size="large"
        onClick={handleSelectEraserTool}
      />
    </Popover>
  );
}

const PopoverContent = styled.div`
  display: flex;
  flex-direction: row;

  .ant-slider {
    width: 200px;
  }

  .ant-input-number-input {
    width: 50px;
  }
`;
