interface SeenImages {
  [coords: string]: string;
}

const storageKey = 'seenImages';
const seenImages: SeenImages = JSON.parse(localStorage.getItem(storageKey) || '{}');

export const imgCache = {
  setSeen,
  isSeen,
};

function isSeen(x: number, y: number, url: string) {
  return seenImages[makeKey(x, y)] === url;
}

function setSeen(x: number, y: number, url: string) {
  seenImages[makeKey(x, y)] = url;
  persistSeenImages();
}

function makeKey(x: number, y: number) {
  return `${x},${y}`;
}

function persistSeenImages() {
  localStorage.setItem(storageKey, JSON.stringify(seenImages));
}
