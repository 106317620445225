import { Stage } from 'konva/lib/Stage';
import { Vector2d } from 'konva/lib/types';

import { CELL_SIZE_PX } from '../constants';
import { areRectsIntersecting } from './areRectsIntersecting';
import { cellToCoordinates } from './cellToCoordinates';
import { getPositionFromCoordinates } from './getPositionFromCoordinates';

export function isCellVisible({
  cell,
  stage,
  margin = CELL_SIZE_PX / 2,
  windowW = window.innerWidth,
  windowH = window.innerHeight,
}: {
  cell: Vector2d;
  stage: Stage;
  margin: number;
  windowW: number;
  windowH: number;
}): boolean {
  const { x: topLeftX, y: topLeftY } = cellToCoordinates(cell);
  const bottomRightX = topLeftX + CELL_SIZE_PX;
  const bottomRightY = topLeftY + CELL_SIZE_PX;
  const topLeftPos = getPositionFromCoordinates(stage, { x: topLeftX, y: topLeftY });
  const bottomRightPos = getPositionFromCoordinates(stage, { x: bottomRightX, y: bottomRightY });

  if (!topLeftPos || !bottomRightPos) {
    return false;
  }

  const { x: topLeftPosX, y: topLeftPosY } = topLeftPos;
  const { x: bottomRightPosX, y: bottomRightPosY } = bottomRightPos;

  // console.log(
  //   'visibility lol',
  //   cell,
  //   {
  //     left: topLeftPosX - margin,
  //     right: bottomRightPosX + margin,
  //     top: topLeftPosY - margin,
  //     bottom: bottomRightPosY + margin,
  //   },
  //   {
  //     left: 0,
  //     right: windowW,
  //     top: 0,
  //     bottom: windowH,
  //   },
  //   areRectsIntersecting(
  //     {
  //       left: topLeftPosX - margin,
  //       right: bottomRightPosX + margin,
  //       top: topLeftPosY - margin,
  //       bottom: bottomRightPosY + margin,
  //     },
  //     {
  //       left: 0,
  //       right: windowW,
  //       top: 0,
  //       bottom: windowH,
  //     }
  //   )
  // );

  return areRectsIntersecting(
    {
      left: topLeftPosX - margin,
      right: bottomRightPosX + margin,
      top: topLeftPosY - margin,
      bottom: bottomRightPosY + margin,
    },
    {
      left: 0,
      right: windowW,
      top: 0,
      bottom: windowH,
    }
  );
}
