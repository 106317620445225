export const blue = '#009bff';
export const violet = '#6500ce';
export const orange = '#f69520';

// export const white = '#e6e7e9';
export const white = '#f0e5fa';
export const darkWhite = '#5a5b63';
export const lightGray = '#b9b9ba';
export const gray = '#27282e';
export const darkGray = '#1b1c21';
export const black = '#15161a';
export const purple = '#6500ce';
