import { Layer, Rect } from 'react-konva';
import shallow from 'zustand/shallow';

import { black } from '../../../colors';
import { CELL_SIZE_PX } from '../../../constants';
import { useGridConfigStore } from '../../../hooks';

const framePaddingCells = 50;

const CanvasFrame = () => {
  const { config, loading } = useGridConfigStore(
    (state) => ({ config: state.config, loading: state.loading }),
    shallow
  );

  return (
    <Layer name={'frame-layer'} listening={false} opacity={1}>
      {!loading && (
        <>
          <Rect
            x={-framePaddingCells * CELL_SIZE_PX}
            y={-framePaddingCells * CELL_SIZE_PX}
            width={(config!.width + 2 * framePaddingCells) * CELL_SIZE_PX}
            height={(config!.height + 2 * framePaddingCells) * CELL_SIZE_PX}
            stroke={black}
            fill={black}
            listening={false}
          />
          <Rect
            x={0}
            y={0}
            width={config!.width * CELL_SIZE_PX}
            height={config!.height * CELL_SIZE_PX}
            stroke={'black'}
            fill={'black'}
            listening={false}
            globalCompositeOperation={'destination-out'}
          />
          <Rect
            x={0}
            y={0}
            width={config!.width * CELL_SIZE_PX}
            height={config!.height * CELL_SIZE_PX}
            stroke={'gold'}
            strokeWidth={5}
            listening={false}
          />
        </>
      )}
    </Layer>
  );
};

export default CanvasFrame;
