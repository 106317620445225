import create from 'zustand';
import clamp from 'lodash/clamp';

export type CanvasTool = 'drag' | 'eraser';
export type CanvasCursor = 'grab' | 'default';

export type MaskLine = {
  tool: CanvasTool;
  strokeWidth: number;
  points: number[];
};

interface ICanvasEditorStoreState {
  tool: CanvasTool;
  cursor: 'grab' | 'default';
  isDrawing: boolean;
  minBrushSize: number;
  maxBrushSize: number;
  brushSize: number;
  lines: MaskLine[];
  pastLines: MaskLine[][];
  futureLines: MaskLine[][];
}

interface ICanvasEditorStoreMethods {
  setTool: (tool: CanvasTool) => void;
  setBrushSize: (size: number) => void;
  setIsDrawing: (isDrawing: boolean) => void;
  addLine: (line: MaskLine) => void;
  addPointToCurrentLine: (points: number[]) => void;
  undo: () => void;
  redo: () => void;
  clearLines: () => void;
  forgetLines: () => void;
}

interface ICanvasEditorHook extends ICanvasEditorStoreState, ICanvasEditorStoreMethods {}

const minBrushSize = 10;
const maxBrushSize = 200;

const defaultState: ICanvasEditorStoreState = {
  tool: 'drag',
  cursor: 'grab',
  isDrawing: false,
  minBrushSize,
  maxBrushSize,
  brushSize: 50,
  lines: [],
  pastLines: [],
  futureLines: [],
};

export const useCanvasEditorStore = create<ICanvasEditorStoreState>(() => defaultState);

export const useCanvasEditorStoreMethods: ICanvasEditorStoreMethods = {
  setTool: (tool: CanvasTool) => {
    const cursor: CanvasCursor = tool === 'drag' ? 'grab' : 'default';
    console.log('WTF setTool', tool, cursor);
    useCanvasEditorStore.setState({ tool, cursor });
  },
  setBrushSize: (size: number) => {
    useCanvasEditorStore.setState({ brushSize: clamp(size, minBrushSize, maxBrushSize) });
  },
  setIsDrawing: (isDrawing: boolean) => {
    useCanvasEditorStore.setState({ isDrawing });
  },
  addLine: (line: MaskLine) => {
    useCanvasEditorStore.setState((state) => ({
      pastLines: [...state.pastLines, state.lines],
      lines: [...state.lines, line],
      futureLines: [],
    }));
  },
  addPointToCurrentLine: (points: number[]) => {
    useCanvasEditorStore.setState((state) => {
      const line = state.lines.pop();
      if (line) {
        line.points = [...line.points, ...points];
        return { lines: [...state.lines, line] };
      }
      return {};
    });
  },
  undo: () => {
    const state = useCanvasEditorStore.getState();
    const pastLines = [...state.pastLines];
    const futureLines = [...state.futureLines];
    let lines = [...state.lines];
    if (pastLines.length === 0) {
      return;
    }
    const newLines = pastLines.pop();
    if (!newLines) {
      return;
    }
    futureLines.unshift(lines);
    lines = newLines;
    useCanvasEditorStore.setState({
      pastLines,
      lines,
      futureLines,
    });
  },
  redo: () => {
    const state = useCanvasEditorStore.getState();
    const pastLines = [...state.pastLines];
    const futureLines = [...state.futureLines];
    let lines = [...state.lines];
    if (futureLines.length === 0) {
      return;
    }
    const newLines = futureLines.shift();
    if (!newLines) {
      return;
    }
    pastLines.push(lines);
    lines = newLines;
    useCanvasEditorStore.setState({
      pastLines,
      lines,
      futureLines,
    });
  },
  clearLines: () => {
    const state = useCanvasEditorStore.getState();
    useCanvasEditorStore.setState({ pastLines: [...state.pastLines, state.lines], lines: [], futureLines: [] });
  },
  forgetLines: () => {
    useCanvasEditorStore.setState({ pastLines: [], lines: [], futureLines: [] });
  },
};

export function useCanvasEditor(): ICanvasEditorHook {
  const storeState = useCanvasEditorStore();

  return { ...storeState, ...useCanvasEditorStoreMethods };
}
