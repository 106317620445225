import { Button, Tooltip } from 'antd';
import { useHotkeys } from 'react-hotkeys-hook';
import { FaRedo } from 'react-icons/fa';
import shallow from 'zustand/shallow';

import { useCanvasEditorStore, useCanvasEditorStoreMethods } from '../../../hooks';

export default function InpaintingRedoControl() {
  const { futureLines } = useCanvasEditorStore((state) => ({ futureLines: state.futureLines }), shallow);
  const canRedo = futureLines.length > 0;

  const handleRedo = () => useCanvasEditorStoreMethods.redo();

  // Hotkeys
  // Redo
  useHotkeys(
    'cmd+shift+z, control+shift+z, control+y, cmd+y',
    (e: KeyboardEvent) => {
      e.preventDefault();
      handleRedo();
    },
    {
      // enabled: activeTabName === 'inpainting' && shouldShowMask && canRedo,
      enabled: canRedo,
    },
    [canRedo]
  );

  return (
    <Tooltip placement="right" title="Undo (ctrl+Y / cmd+Y)">
      <Button type="default" disabled={!canRedo} icon={<FaRedo />} size="large" onClick={handleRedo} />
    </Tooltip>
  );
}
