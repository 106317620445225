import { Vector2d } from 'konva/lib/types';
import { useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import { calcFocusOnCell } from '../utils';
import { useCanvasStore } from './useCanvas';
import { useGridStore, useGridStoreMethods } from './useGrid';
import { useGridConfigStore } from './useGridConfig';
import { IGridConfigResult } from './useWsConnection';

export const initialCoords = parseUrl();

export function useLinkToCell() {
  const { stageRef } = useCanvasStore((state) => ({ stageRef: state.stageRef }), shallow);
  const { config } = useGridConfigStore((state) => ({ config: state.config }), shallow);
  const { selectedCell } = useGridStore((state) => ({ selectedCell: state.selectedCell }), shallow);
  const [initialized, setInitialized] = useState(false);
  const [scaleAndPos, setScaleAndPos] = useState<{ scale: number; position: Vector2d }>();

  useEffect(() => {
    if (initialized) return;
    if (!initialCoords) {
      setInitialized(true);
      return;
    }
    if (!config) return;

    if (isValidCoords(initialCoords, config)) {
      useGridStoreMethods.setSelectedCell(initialCoords);
      const newScaleAndPos = calcFocusOnCell({
        cell: initialCoords,
      });
      setScaleAndPos(newScaleAndPos);
    } else {
      setLinkToCell(null);
    }
    setInitialized(true);
  }, [config, initialized, stageRef]);

  useEffect(() => {
    if (!stageRef.current) return;
    if (!config) return;

    setLinkToCell(selectedCell);
    // we need to react to changes in stageRef.current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageRef.current, config, selectedCell]);

  return { initialized, scaleAndPos };
}

function parseUrl() {
  let coords: Vector2d | null = null;

  try {
    const [x, y] = (window.location.pathname || '')
      .split('@')[1]
      .split(',')
      .map(Number)
      .filter((n) => !Number.isNaN(n));

    if (x != null && y != null) {
      coords = { x, y };
    }
  } catch (e) {}

  return coords;
}

export function isValidCoords(coords: Vector2d | null, config: IGridConfigResult) {
  return coords && coords.x >= 0 && coords.x < config.width && coords.y >= 0 && coords.y < config.height;
}

function setLinkToCell(coords: Vector2d | null) {
  if (coords) {
    window.history.replaceState(null, '', window.location.origin + `/@${coords.x},${coords.y}`);
  } else {
    window.history.replaceState(null, '', window.location.origin);
  }
}
