import styled from 'styled-components';
import cn from 'classnames';

import { HEADER_HEIGHT } from '../../../constants';
import CanvasEditorDragControl from './CanvasEditorDragControl';
import CanvasEditorEraserControl from './CanvasEditorEraserControl';
import CanvasEditorMaskClear from './CanvasEditorMaskClear';
import CanvasEditorUndoControl from './CanvasEditorUndoControl';
import CanvasEditorRedoControl from './CanvasEditorRedoControl';

interface IProps {
  open: boolean;
}

const CanvasEditorControls = ({ open }: IProps) => {
  return (
    <Container className={cn({ open })}>
      <div className="buttonGroup">
        <CanvasEditorDragControl />
        <CanvasEditorEraserControl />
      </div>
      <div className="buttonGroup">
        <CanvasEditorMaskClear />
        <CanvasEditorUndoControl />
        <CanvasEditorRedoControl />
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  left: 0;
  top: ${HEADER_HEIGHT}px;
  z-index: 2;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  transform: translateX(-100%);
  transition: transform 0.2s ease;

  &.open {
    transform: translateX(0);
  }

  .buttonGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

export default CanvasEditorControls;
