import styled from 'styled-components';
import { FaRegEdit, FaEye, FaShareAlt } from 'react-icons/fa';
import { Vector2d } from 'konva/lib/types';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import shallow from 'zustand/shallow';

import {
  CanvasMode,
  IGridCellResult,
  useCanvasStore,
  useCanvasStoreMethods,
  useGenerateImagesStore,
} from '../../../hooks';
import { TroveLogo } from '../../common';
import { focusOnCell } from '../../../utils';

interface IProps {
  loading: boolean;
  mode: CanvasMode;
  selectedCoords?: Vector2d | null;
  selectedCell?: IGridCellResult | null;
  isCellOwner: boolean;
}

const CellHeader = ({ loading, mode, selectedCoords, selectedCell, isCellOwner }: IProps) => {
  const { stageRef } = useCanvasStore((state) => ({ stageRef: state.stageRef }), shallow);
  const { generatingImages } = useGenerateImagesStore((state) => ({ generatingImages: state.loading }), shallow);

  const handleSetEditMode = () => {
    const { scale } = focusOnCell({ stage: stageRef.current!, cell: selectedCoords! });
    useCanvasStoreMethods.setScale(scale);
    useCanvasStoreMethods.setMode('edit');
  };
  const handleSetViewMode = () => useCanvasStoreMethods.setMode('view');
  const handleViewOnTrove = () => {
    //
  };
  const handleShare = () => {
    //
  };

  return (
    <Container>
      <h1>
        {'{'}
        {selectedCoords?.x}, {selectedCoords?.y}
        {'}'}
      </h1>
      <div className="cellImgContainer">
        <>
          {!loading && !!(selectedCell as IGridCellResult)?.url && (
            <img src={(selectedCell as IGridCellResult).url} alt="Cell" className="cellImg" />
          )}
          {loading && (
            <div className="loaderContainer">
              <LoadingOutlined className="loader" />
            </div>
          )}
          <div className="buttons">
            {isCellOwner && (
              <>
                {mode === 'view' && (
                  <Tooltip placement="top" title="Edit">
                    <Button type={'default'} icon={<FaRegEdit />} size="large" onClick={handleSetEditMode} />
                  </Tooltip>
                )}
                {mode === 'edit' && (
                  <Tooltip placement="top" title="View (cancel editing)">
                    <Button
                      type={'default'}
                      icon={<FaEye />}
                      size="large"
                      disabled={generatingImages}
                      onClick={handleSetViewMode}
                    />
                  </Tooltip>
                )}
              </>
            )}
            <Tooltip placement="top" title="View on Trove">
              {/* <Button type={'default'} icon={<FaExternalLinkAlt />} size="large" onClick={handleViewOnTrove} /> */}
              <Button type={'default'} icon={<TroveLogo />} size="large" onClick={handleViewOnTrove} />
            </Tooltip>
            <Tooltip placement="top" title="Share">
              <Button type={'default'} icon={<FaShareAlt />} size="large" onClick={handleShare} />
            </Tooltip>
          </div>
        </>
      </div>
    </Container>
  );
};

const Container = styled.div`
  h1 {
    text-align: center;
  }

  .cellImgContainer {
    width: 256px;
    height: 256px;
    position: relative;
  }

  .cellImg {
    width: 100%;
    height: 100%;
  }

  .loaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loader svg {
    width: 60px;
    height: 60px;
  }

  .buttons {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
`;

export default CellHeader;
