import { Stage } from 'konva/lib/Stage';
import { Vector2d } from 'konva/lib/types';
import clamp from 'lodash/clamp';

import { MAX_CANVAS_SCALE, MIN_CANVAS_SCALE } from '../constants';

export type Spacing = [number, number, number, number];

// padding - how much space around the center point needs to be visible
// margin - how much space from each side of the canvas should be considered obscured (e.g. by header)
export function focusOnCoords({
  stage,
  coords,
  padding = 0,
  margin = 0,
  scale,
}: {
  stage: Stage;
  coords: Vector2d;
  padding?: number;
  margin?: number | Spacing;
  scale?: number;
}): { scale: number } {
  const { scale: newScale, position } = calcFocusOnCoords({
    coords,
    padding,
    margin,
    scale,
  });

  stage.scale({ x: newScale, y: newScale });
  stage.position(position);

  return { scale: newScale };
}

export function calcFocusOnCoords({
  coords,
  padding = 0,
  margin = 0,
  scale,
}: {
  coords: Vector2d;
  padding?: number;
  margin?: number | Spacing;
  scale?: number;
}): { scale: number; position: Vector2d } {
  const margins = parseMargins(margin);
  const canvasW = window.innerWidth - (margins.l + margins.r);
  const canvasH = window.innerHeight - (margins.t + margins.b);

  let newScale = scale || 0;
  if (!scale) {
    const newScaleX = canvasW / (padding === 0 ? canvasW : 2 * padding);
    const newScaleY = canvasH / (padding === 0 ? canvasH : 2 * padding);
    newScale = clamp(Math.min(newScaleX, newScaleY), MIN_CANVAS_SCALE, MAX_CANVAS_SCALE);
  }

  const scaledPosX = coords.x * newScale;
  const scaledPosY = coords.y * newScale;

  const offsetX = canvasW / 2;
  const offsetY = canvasH / 2;

  const adjustedPosX = scaledPosX - offsetX - margins.l;
  const adjustedPosY = scaledPosY - offsetY - margins.t;

  return { scale: newScale, position: { x: -adjustedPosX, y: -adjustedPosY } };
}

function parseMargins(margin: number | Spacing): { l: number; t: number; r: number; b: number } {
  if (Array.isArray(margin)) {
    const [l, t, r, b] = margin;
    return { l, t, r, b };
  }

  return { l: margin, t: margin, r: margin, b: margin };
}
