import React, { useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import { useCanvasStore, useGridStore } from '../../../hooks';
import { cellToOffset, imgCache, isCellVisible } from '../../../utils';
import LazyKonvaImageFromUrl from './LazyKonvaImageFromUrl';

const CanvasCells = () => {
  const { cells } = useGridStore((state) => ({ cells: state.cells }), shallow);
  const { stageRef, scale } = useCanvasStore((state) => ({ stageRef: state.stageRef, scale: state.scale }), shallow);
  const [updater, setUpdater] = useState(0);

  const [lazyCells, setLazyCells] = useState<React.ReactNode[]>([]);

  // const lazyCells = useMemo(() => {
  //   if (!stageRef.current) {
  //     return null;
  //   }
  //   const windowW = window.innerWidth;
  //   const windowH = window.innerHeight;

  //   return cells.map((cell) => {
  //     seenCells[cell.url] =
  //       seenCells[cell.url] || isCellVisible({ cell, stage: stageRef.current!, margin: 0, windowW, windowH });

  //     console.log(
  //       'cell visibility:',
  //       cell,
  //       scale,
  //       isCellVisible({ cell, stage: stageRef.current!, margin: 0, windowW, windowH }),
  //       seenCells[cell.url]
  //     );
  //     return (
  //       <LazyKonvaImageFromUrl key={cell.url} src={cell.url} load={seenCells[cell.url]} offset={cellToOffset(cell)} />
  //     );
  //   });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [cells, stageRef, updater, scale]);

  useEffect(() => {
    if (!stageRef.current) {
      return;
    }
    const windowW = window.innerWidth;
    const windowH = window.innerHeight;

    const res = cells
      .filter((cell) => cell.url)
      .map((cell) => {
        let load = imgCache.isSeen(cell.x, cell.y, cell.url!);
        if (!load) {
          const isVisible = isCellVisible({ cell, stage: stageRef.current!, margin: 0, windowW, windowH });
          if (isVisible) {
            imgCache.setSeen(cell.x, cell.y, cell.url!);
            load = true;
          }
        }

        // console.log(
        //   'cell visibility:',
        //   cell,
        //   scale,
        //   isCellVisible({ cell, stage: stageRef.current!, margin: 0, windowW, windowH }),
        //   seenCells[cell.url]
        // );
        return <LazyKonvaImageFromUrl key={cell.url} src={cell.url!} load={load} offset={cellToOffset(cell)} />;
      });

    setLazyCells(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cells, stageRef, updater, scale]);

  useEffect(() => {
    if (!stageRef.current) return;

    const stage = stageRef.current;

    // const updateVal = throttle(() => setUpdater((value) => value + 1), 30);
    const updateVal = () => setUpdater((value) => value + 1);

    stage.on('dragstart', updateVal);
    stage.on('dragmove', updateVal);
    stage.on('dragend', updateVal);

    return () => {
      if (stage) {
        stage.off('dragstart', updateVal);
        stage.off('dragmove', updateVal);
        stage.off('dragend', updateVal);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageRef.current]);

  return <>{lazyCells}</>;
};

export default CanvasCells;
