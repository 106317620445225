import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Modal, Tooltip } from 'antd';
import { FaArrowUp, FaRegEdit } from 'react-icons/fa';
import Linkify from 'react-linkify';

import { FlexSpacer } from '../../common';
import { IGridCellDetails, useGridStoreMethods } from '../../../hooks';

interface IProps {
  isCellOwner: boolean;
  cell: IGridCellDetails;
  onRefresh: () => Promise<void>;
}

const ViewMode = ({ isCellOwner, cell, onRefresh }: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedNote, setUpdatedNote] = useState('');
  const [loading, setLoading] = useState(false);
  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
    setUpdatedNote(cell.note);
  }, [cell.note]);
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const handleNoteChange = useCallback((e: any) => {
    setUpdatedNote(e.target.value);
  }, []);
  const handleEditNote = useCallback(async () => {
    try {
      setLoading(true);
      await useGridStoreMethods.updateNote(cell, updatedNote);
      await onRefresh();
      handleCloseModal();
    } finally {
      setLoading(false);
      setUpdatedNote('');
    }
  }, [cell, handleCloseModal, onRefresh, updatedNote]);

  return (
    <Container>
      <Modal
        title="Edit Note"
        open={isModalOpen}
        okText="Save"
        confirmLoading={loading}
        closable={false}
        maskClosable={false}
        onOk={handleEditNote}
        onCancel={handleCloseModal}
      >
        <Form.Item label="" colon={false} labelAlign="left">
          <Input.TextArea
            rows={3}
            showCount
            maxLength={400}
            placeholder="Public description (may include links)"
            value={updatedNote}
            disabled={loading}
            onChange={handleNoteChange}
          />
        </Form.Item>
      </Modal>
      <Form.Item label="PROMPT" colon={false} labelAlign="left">
        <div className="quote">{cell.prompt || '-'}</div>
      </Form.Item>
      <Form.Item
        label={
          <div>
            NOTE{' '}
            {isCellOwner && (
              <Tooltip placement="top" title="Edit Note">
                <Button
                  className="noteEditBtn"
                  type="ghost"
                  icon={<FaRegEdit />}
                  size="small"
                  onClick={handleOpenModal}
                />
              </Tooltip>
            )}
          </div>
        }
        colon={false}
        labelAlign="left"
      >
        <div className="quote">
          <Linkify
            componentDecorator={(href, text, key) => (
              <a key={key} href={href} target="_blank" rel="noreferrer">
                {text}
              </a>
            )}
          >
            {cell.note || '-'}
          </Linkify>
        </div>
      </Form.Item>
      <FlexSpacer />
      <div className="footer">
        <hr />
        <span className="description">Vote on this NFT with gMAGIC</span>
        <Button className="voteBtn" type="primary" size="large">
          <FaArrowUp /> Vote
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-form-item .ant-row {
    flex-direction: column;
  }

  .noteEditBtn {
    margin-left: 8px;
    vertical-align: bottom;
  }

  .quote {
    font-style: italic;
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    hr {
      margin: 0;
    }

    .description {
      text-align: center;
    }
  }

  .voteBtn svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    vertical-align: middle;
  }
`;

export default ViewMode;
