import { Circle } from 'react-konva';
import shallow from 'zustand/shallow';

import { useCanvasEditorStore, useCanvasStore, useGridConfigStore, useGridStore } from '../../../hooks';
import { getCellFromCursorPosition, isSameCell } from '../../../utils';

/**
 * Draws a black circle around the canvas brush preview.
 */
const CanvasEditorBrush = () => {
  const { cursorPosition, stageRef } = useCanvasStore(
    (state) => ({ cursorPosition: state.cursorPosition, stageRef: state.stageRef }),
    shallow
  );
  const { tool, brushSize } = useCanvasEditorStore(
    (state) => ({ tool: state.tool, brushSize: state.brushSize }),
    shallow
  );
  const { selectedCell } = useGridStore((state) => ({ selectedCell: state.selectedCell }), shallow);
  const { config } = useGridConfigStore((state) => ({ config: state.config }), shallow);

  if (!cursorPosition) return null;
  if (tool !== 'eraser') return null;

  const cursorCell = getCellFromCursorPosition(stageRef.current!, config);

  if (!isSameCell(selectedCell, cursorCell)) return null;

  return (
    <Circle
      x={cursorPosition.x}
      y={cursorPosition.y}
      radius={brushSize / 2}
      fill={'darkgray'}
      listening={false}
      globalCompositeOperation={'destination-out'}
    />
  );
};

export default CanvasEditorBrush;
