import { Vector2d } from 'konva/lib/types';

import { cellToCoordinates } from './cellToCoordinates';

export function cellToOffset(cell: Vector2d): Vector2d {
  const coordinates = cellToCoordinates(cell);

  return {
    x: -coordinates.x,
    y: -coordinates.y,
  };
}
