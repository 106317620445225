import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { Layer, Circle } from 'react-konva';
import styled from 'styled-components';
import shallow from 'zustand/shallow';

import { CELL_SIZE_PX } from '../../../constants';
import { useCanvasStore, useGridConfigStore } from '../../../hooks';

const CanvasImageBg = () => {
  const { scale, stagePosition } = useCanvasStore(
    (state) => ({ scale: state.scale, stagePosition: state.stagePosition }),
    shallow
  );

  console.log('STAGE POS', scale, stagePosition);

  return <Container scale={scale} position={stagePosition!} />;
};

const Container = styled.div<{ scale: number; position: Vector2d }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='rgb(84, 84, 84)' fill-opacity='1' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='1'/%3E%3C/g%3E%3C/svg%3E");
`;

// background-image: url(\"${(props) => getScaledSvg(props.scale)}\");
// /* background-position: ${(props) => -props.position.x * props.scale}px ${(props) =>
// -props.position.y * props.scale}px; */

/* background-repeat: no-repeat; */

function getScaledSvg(scale: number) {
  const baseSize = 200;
  const scaledSize = baseSize * scale;
  return `data:image/svg+xml,%3Csvg width='${scaledSize}' height='${scaledSize}' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='rgb(84, 84, 84)' fill-opacity='1' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='1'/%3E%3C/g%3E%3C/svg%3E`;
}

export default CanvasImageBg;
