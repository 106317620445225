import { useEffect, useState } from 'react';
import styled from 'styled-components';
import shallow from 'zustand/shallow';
import { LoadingOutlined } from '@ant-design/icons';

import {
  useCanvasStore,
  useCanvasStoreMethods,
  useGridConfigStore,
  useGridStore,
  useInitialScaleAndPos,
  useWsConnection,
} from '../../hooks';
import SidePanel from './SidePanel';
import CanvasStage from './CanvasStage/CanvasStage';
import CanvasEditorControls from './CanvasEditorControls';
import HighlightOwnCells from './HighlightOwnCells';
import GenerationsControls from './GenerationsControls';

const GridCanvas = () => {
  useWsConnection();
  const { mode } = useCanvasStore((state) => ({ mode: state.mode }), shallow);
  const { loading: configLoading } = useGridConfigStore((state) => ({ loading: state.loading }), shallow);
  const { selectedCell, loading: gridLoading } = useGridStore(
    (state) => ({ selectedCell: state.selectedCell, loading: state.loading }),
    shallow
  );
  const { initialized: scaleAndPosInitialized, scaleAndPos } = useInitialScaleAndPos();
  const [ready, setReady] = useState(false);
  console.log(mode);

  useEffect(() => {
    if (ready) return;
    if (!scaleAndPosInitialized) return;

    useCanvasStoreMethods.setScale(scaleAndPos!.scale);
    useCanvasStoreMethods.setStagePosition(scaleAndPos!.position);
    setReady(true);
  }, [ready, scaleAndPos, scaleAndPosInitialized]);

  if (!ready || configLoading || gridLoading) {
    return (
      <LoaderContainer>
        <LoadingOutlined className="loader" />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      <SidePanel open={!!selectedCell && mode !== 'pickGeneration'} />
      <CanvasEditorControls open={!!selectedCell && mode === 'edit'} />
      <HighlightOwnCells />
      {mode === 'pickGeneration' && <GenerationsControls />}
      <CanvasStage initialPos={scaleAndPos!.position} />
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100%;
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  .loader svg {
    width: 200px;
    height: 200px;
  }
`;

export default GridCanvas;
