import { Button } from 'antd';
import { useCallback } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import styled from 'styled-components';
import { useAccount } from 'wagmi';
import shallow from 'zustand/shallow';

import { useGenerateImagesStore, useGenerateImagesStoreMethods, useGridStore } from '../../hooks';
import { PositionRelativeToCell } from '../common';

const GenerationsControls = () => {
  const { address } = useAccount();
  const { selectedCell } = useGridStore((state) => ({ selectedCell: state.selectedCell }), shallow);
  const { generatedImages, selectedIndex } = useGenerateImagesStore(
    (state) => ({ generatedImages: state.generatedImages, selectedIndex: state.selectedIndex }),
    shallow
  );

  const handlePrev = useCallback(() => {
    useGenerateImagesStoreMethods.prevImage();
  }, []);
  const handleNext = useCallback(() => {
    useGenerateImagesStoreMethods.nextImage();
  }, []);
  const handleCancel = useCallback(() => {
    useGenerateImagesStoreMethods.cancelGeneration({ generationId: generatedImages[0].generationId });
  }, [generatedImages]);
  const handleAccept = useCallback(() => {
    useGenerateImagesStoreMethods.acceptGeneration({ address: address!, ...generatedImages[selectedIndex] });
  }, [address, generatedImages, selectedIndex]);

  if (!selectedCell) {
    return null;
  }

  return (
    <PositionRelativeToCell placement="bottom" cell={selectedCell}>
      <Container>
        <Button.Group>
          <Button type="primary" icon={<FaChevronLeft />} onClick={handlePrev} />
          <Button type="primary" className="index">
            {selectedIndex + 1}/{generatedImages.length}
          </Button>
          <Button type="primary" icon={<FaChevronRight />} onClick={handleNext} />
          <Button type="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleAccept}>
            Accept
          </Button>
        </Button.Group>
      </Container>
    </PositionRelativeToCell>
  );
};

const Container = styled.div`
  transform: translate(-50%, 30px);
  display: flex;
  flex-direction: row;

  .ant-btn.index:hover {
    cursor: default;
  }
`;

export default GenerationsControls;
