import { useCallback, useEffect, useRef } from 'react';
import Konva from 'konva';
import { Layer, Stage } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Vector2d } from 'konva/lib/types';
import shallow from 'zustand/shallow';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import clamp from 'lodash/clamp';

import { getScaledCursorPosition, getCellFromCursorPosition, isSameCell, focusOnCell } from '../../../utils';
import {
  useCanvasEditorStore,
  useCanvasEditorStoreMethods,
  useCanvasStore,
  useCanvasStoreMethods,
  useGridConfigStore,
  useGridStore,
  useGridStoreMethods,
} from '../../../hooks';
import CanvasEditorLines from './CanvasEditorLines';
import CanvasEditorBrush from './CanvasEditorBrush';
import CanvasEditorBrushOutline from './CanvasEditorBrushOutline';
import CanvasCellBoundingBox from './CanvasCellBoundingBox';
import KeyboardEventManager from './KeyboardEventManager';
import CanvasGenerationsPreview from './CanvasGenerationsPreview';
import CanvasFrame from './CanvasFrame';
import CanvasCells from './CanvasCells';
import CanvasSurroundingCells from './CanvasSurroundingCells';
import CanvasBg from './CanvasBg';
import CanvasOwnCellsHighlight from './CanvasOwnCellsHighlight';
import CanvasImageBg from './CanvasImageBg';
import { CELL_SIZE_PX } from '../../../constants';

interface IProps {
  initialPos: Vector2d;
}

const CanvasStage = ({ initialPos }: IProps) => {
  const { selectedCell } = useGridStore((state) => ({ selectedCell: state.selectedCell }), shallow);
  const { config, loading } = useGridConfigStore(
    (state) => ({ config: state.config, loading: state.loading }),
    shallow
  );
  const { mode, scale, stageRef } = useCanvasStore(
    (state) => ({ mode: state.mode, scale: state.scale, stageRef: state.stageRef }),
    shallow
  );
  const { tool, cursor, isDrawing, brushSize } = useCanvasEditorStore(
    (state) => ({
      tool: state.tool,
      cursor: state.cursor,
      isDrawing: state.isDrawing,
      brushSize: state.brushSize,
    }),
    shallow
  );

  const maskLayerRef = useRef<Konva.Layer>(null);
  const lastCursorPosition = useRef<Vector2d>({ x: 0, y: 0 });

  // Use refs for values that do not affect rendering, other values in redux
  const didMouseMoveRef = useRef<boolean>(false);

  const stageRefCallback = useCallback(
    (node: any) => {
      console.log('callbackRef', node);
      stageRef.current = node;
    },
    [stageRef]
  );

  console.log('ref', stageRef.current);

  const handleMouseDown = useCallback(() => {
    if (!stageRef.current) return;

    const scaledCursorPosition = getScaledCursorPosition(stageRef.current);

    if (!scaledCursorPosition || !maskLayerRef.current) return;

    const pointerPosition = stageRef.current!.getPointerPosition();
    console.log(pointerPosition, scaledCursorPosition, getCellFromCursorPosition(stageRef.current, config));

    const cursorCell = getCellFromCursorPosition(stageRef.current, config);

    if (mode === 'edit' && tool === 'eraser' && isSameCell(selectedCell, cursorCell)) {
      useCanvasEditorStoreMethods.setIsDrawing(true);

      // Add a new line starting from the current cursor position.
      useCanvasEditorStoreMethods.addLine({
        tool,
        strokeWidth: brushSize / 2,
        points: [scaledCursorPosition.x, scaledCursorPosition.y],
      });
    }
  }, [stageRef, config, mode, tool, selectedCell, brushSize]);

  const handleMouseMove = useCallback(() => {
    if (!stageRef.current) return;

    const scaledCursorPosition = getScaledCursorPosition(stageRef.current);

    if (!scaledCursorPosition) return;

    useCanvasStoreMethods.setCursorPosition(scaledCursorPosition);

    if (!maskLayerRef.current) {
      return;
    }

    lastCursorPosition.current = scaledCursorPosition;

    if (!isDrawing) return;

    didMouseMoveRef.current = true;

    const cursorCell = getCellFromCursorPosition(stageRef.current, config);

    if (mode === 'edit' && tool === 'eraser' && isSameCell(selectedCell, cursorCell)) {
      useCanvasEditorStoreMethods.addPointToCurrentLine([scaledCursorPosition.x, scaledCursorPosition.y]);
    }
  }, [config, isDrawing, mode, selectedCell, stageRef, tool]);

  const handleMouseUp = useCallback(() => {
    if (!stageRef.current) {
      return;
    }

    if (!didMouseMoveRef.current && stageRef.current) {
      const scaledCursorPosition = getScaledCursorPosition(stageRef.current);

      if (!scaledCursorPosition || !maskLayerRef.current) return;

      const cursorCell = getCellFromCursorPosition(stageRef.current, config);

      /**
       * Extend the current line.
       * In this case, the mouse didn't move, so we append the same point to
       * the line's existing points. This allows the line to render as a circle
       * centered on that point.
       */
      if (mode === 'edit' && tool === 'eraser' && isSameCell(selectedCell, cursorCell)) {
        useCanvasEditorStoreMethods.addPointToCurrentLine([scaledCursorPosition.x, scaledCursorPosition.y]);
      }
    } else {
      didMouseMoveRef.current = false;
    }
    useCanvasEditorStoreMethods.setIsDrawing(false);
  }, [config, mode, selectedCell, stageRef, tool]);

  const handleMouseOutCanvas = useCallback(() => {
    useCanvasStoreMethods.setCursorPosition(null);
  }, []);

  const handleMouseEnter = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (e.evt.buttons === 1) {
        if (!stageRef.current) return;

        const scaledCursorPosition = getScaledCursorPosition(stageRef.current);

        if (!scaledCursorPosition || !maskLayerRef.current) return;

        const cursorCell = getCellFromCursorPosition(stageRef.current, config);

        // Add a new line starting from the current cursor position.
        if (mode === 'edit' && tool === 'eraser' && isSameCell(selectedCell, cursorCell)) {
          useCanvasEditorStoreMethods.addLine({
            tool,
            strokeWidth: brushSize / 2,
            points: [scaledCursorPosition.x, scaledCursorPosition.y],
          });
        }
      }
    },
    [stageRef, config, mode, tool, selectedCell, brushSize]
  );

  const handleWheel = useCallback(
    (e: KonvaEventObject<WheelEvent>) => {
      e.evt.preventDefault();
      const stage = stageRef.current!;

      const oldScale = stage.scaleX();
      const pointer = stage.getPointerPosition()!;

      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };

      const direction = e.evt.deltaY < 0 ? 1 : -1;

      const newScale = direction < 0 ? useCanvasStoreMethods.zoomIn() : useCanvasStoreMethods.zoomOut();

      stage.scale({ x: newScale, y: newScale });

      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stage.position(newPos);

      useCanvasStoreMethods.setScale(newScale);
    },
    [stageRef]
  );

  const handleClick = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      console.log('CLICK', e);
      if (!stageRef.current) {
        return;
      }

      const cell = getCellFromCursorPosition(stageRef.current, config);

      if (!cell) {
        return;
      }

      if (mode === 'view') {
        useGridStoreMethods.setSelectedCell(cell);
      }
    },
    [config, mode, stageRef]
  );

  const dragBoundFunc = useCallback(
    (pos: Vector2d, evt: any) => {
      if (!config) return pos;
      const windowW = window.innerWidth;
      const windowH = window.innerHeight;
      const scaledWindowW = windowW / scale;
      const scaledWindowH = windowH / scale;
      const gridW = config!.width * CELL_SIZE_PX;
      const gridH = config!.height * CELL_SIZE_PX;
      const boundaryPadding = CELL_SIZE_PX * 20;
      const leftXBoundary = boundaryPadding;
      const rightXBoundary = -(gridW + boundaryPadding) + scaledWindowW;
      const topYBoundary = boundaryPadding;
      const bottomYBoundary = -(gridH + boundaryPadding) + scaledWindowH;

      const gridWithPaddingW = gridW + 2 * boundaryPadding;
      const gridWithPaddingH = gridH + 2 * boundaryPadding;

      const scaledX = pos.x / scale;
      const scaledY = pos.y / scale;

      let newScaledX = 0;
      let newScaledY = 0;

      if (gridWithPaddingW > scaledWindowW) {
        console.log('dragBoundFunc xBoundaryRange >>> scaledWindowW');
        newScaledX = clamp(scaledX, rightXBoundary, leftXBoundary);
      } else {
        console.log('dragBoundFunc xBoundaryRange <<< scaledWindowW');
        // center on screen
        newScaledX = (scaledWindowW - gridW) / 2;
      }

      if (gridWithPaddingH > scaledWindowH) {
        console.log('dragBoundFunc yBoundaryRange >>> scaledWindowH');
        newScaledY = clamp(scaledY, bottomYBoundary, topYBoundary);
      } else {
        console.log('dragBoundFunc yBoundaryRange <<< scaledWindowH');
        // center on screen
        newScaledY = (scaledWindowH - gridH) / 2;
      }

      const x = newScaledX * scale;
      const y = newScaledY * scale;

      // console.log('dragBoundFunc', {
      //   scale,
      //   pos,
      //   x,
      //   y,
      //   leftXBoundary,
      //   rightXBoundary,
      //   topYBoundary,
      //   bottomYBoundary,
      //   scaledWindowH,
      //   scaledWindowW,
      //   xBoundaryRange,
      //   yBoundaryRange,
      //   nonabsxBoundaryRange,
      //   nonabsyBoundaryRange,
      //   newScaledX,
      //   newScaledY,
      // });
      return { x, y };
    },
    [config, scale]
  );

  useEffect(() => {
    if (!stageRef.current) return;

    const stage = stageRef.current;

    const updatePos = throttle(() => {
      console.log(
        'STAGE',
        stage.x(),
        stage.y(),
        stage.getAbsoluteTransform().copy().invert().point({ x: stage.x(), y: stage.y() })
      );
      useCanvasStoreMethods.setStagePosition({ x: stage.x(), y: stage.y() });
    }, 1000 / 24);

    stage.on('dragstart', updatePos);
    stage.on('dragmove', updatePos);
    stage.on('dragend', updatePos);

    return () => {
      if (stage) {
        stage.off('dragstart', updatePos);
        stage.off('dragmove', updatePos);
        stage.off('dragend', updatePos);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageRef.current]);

  console.log('WTF INPAINTING CANVAS', selectedCell, cursor, tool, mode);

  return (
    <Container>
      <CanvasImageBg />
      <Stage
        draggable={tool === 'drag'}
        dragBoundFunc={dragBoundFunc as any}
        width={window.innerWidth}
        height={window.innerHeight}
        scale={{ x: scale, y: scale }}
        x={initialPos.x}
        y={initialPos.y}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseUp={handleMouseUp}
        onMouseOut={handleMouseOutCanvas}
        onMouseLeave={handleMouseOutCanvas}
        onClick={handleClick}
        onWheel={handleWheel}
        onTransform={() => console.log('transform')}
        style={{ ...(cursor ? { cursor } : {}) }}
        ref={stageRefCallback}
      >
        {/* <CanvasBg /> */}

        <CanvasFrame />
        <Layer name={'mask-layer'} listening={false} opacity={1} ref={maskLayerRef}>
          <CanvasCells />
          <CanvasOwnCellsHighlight />

          <CanvasEditorLines />
          {mode === 'edit' && <CanvasEditorBrush />}

          {mode === 'edit' && <CanvasSurroundingCells />}

          {mode === 'pickGeneration' && <CanvasGenerationsPreview />}
        </Layer>
        <Layer>
          {!!selectedCell && <CanvasCellBoundingBox />}

          {mode === 'edit' && <CanvasEditorBrushOutline />}
        </Layer>
      </Stage>
      <KeyboardEventManager />
    </Container>
  );
};

const Container = styled.div``;

export default CanvasStage;
