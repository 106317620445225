import { useEffect, useRef, useState } from 'react';

import KonvaImageFromUrl from './KonvaImageFromUrl';

interface IProps {
  src: string;
  load: boolean;
  [key: string]: any;
}

const LazyKonvaImageFromUrl = ({ src, load, ...rest }: IProps) => {
  const [loadTriggered, setLoadTriggered] = useState(false);

  useEffect(() => {
    if (load) {
      setLoadTriggered(load);
    }
  }, [load]);

  if (!loadTriggered) {
    return null;
  }

  return <KonvaImageFromUrl src={src} {...rest} />;
};

export default LazyKonvaImageFromUrl;
