import { Button, Tooltip } from 'antd';
import { useHotkeys } from 'react-hotkeys-hook';
import { FaUndo } from 'react-icons/fa';
import shallow from 'zustand/shallow';

import { useCanvasEditorStore, useCanvasEditorStoreMethods } from '../../../hooks';

export default function InpaintingUndoControl() {
  const { pastLines } = useCanvasEditorStore((state) => ({ pastLines: state.pastLines }), shallow);
  const canUndo = pastLines.length > 0;

  const handleUndo = () => useCanvasEditorStoreMethods.undo();

  // Hotkeys
  // Undo
  useHotkeys(
    'cmd+z, control+z',
    (e: KeyboardEvent) => {
      e.preventDefault();
      handleUndo();
    },
    {
      enabled: canUndo,
      // enabled: activeTabName === 'inpainting' && shouldShowMask && canUndo,
    },
    [canUndo]
  );

  return (
    <Tooltip placement="right" title="Undo (ctrl+Z / cmd+Z)">
      <Button type="default" disabled={!canUndo} icon={<FaUndo />} size="large" onClick={handleUndo} />
    </Tooltip>
  );
}
