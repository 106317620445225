import { ConfigProvider } from 'antd';

import { GridCanvas, SiteHeader } from './components';
import { themeConfig } from './theme';
import GlobalStyle from './GlobalStyle';
import WalletProvider from './WalletProvider';
import { useNotification } from './hooks/useNotification';

const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <WalletProvider>
      <ConfigProvider theme={themeConfig}>{children}</ConfigProvider>
    </WalletProvider>
  );
};

const App = () => {
  const { contextHolder } = useNotification();

  return (
    <AppWrapper>
      {contextHolder}
      <GlobalStyle />
      <SiteHeader />
      <GridCanvas />
    </AppWrapper>
  );
};

export default App;
