import { Line, Rect } from 'react-konva';
import shallow from 'zustand/shallow';

import { useCanvasEditorStore, useCanvasStore, useGridStore } from '../../../hooks';

/**
 * Draws the lines which comprise the mask.
 *
 * Uses globalCompositeOperation to handle the brush and eraser tools.
 */
const CanvasEditorLines = () => {
  const { mode } = useCanvasStore((state) => ({ mode: state.mode }), shallow);
  const { selectedCell } = useGridStore((state) => ({ selectedCell: state.selectedCell }), shallow);
  const { lines } = useCanvasEditorStore((state) => ({ lines: state.lines }), shallow);

  if (mode !== 'edit') return null;
  if (!selectedCell) return null;

  return (
    <>
      {lines.map((line, i) => (
        <Line
          key={i}
          points={line.points}
          stroke={'black'}
          strokeWidth={line.strokeWidth * 2}
          tension={0}
          lineCap="round"
          lineJoin="round"
          shadowForStrokeEnabled={false}
          listening={false}
          globalCompositeOperation="destination-out"
        />
      ))}
    </>
  );
};

export default CanvasEditorLines;
