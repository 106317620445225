import { Vector2d } from 'konva/lib/types';

import { CELL_SIZE_PX } from '../constants';

export function cellToCoordinates(cell: Vector2d): Vector2d {
  return {
    x: cell.x * CELL_SIZE_PX,
    y: cell.y * CELL_SIZE_PX,
  };
}
