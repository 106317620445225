import create from 'zustand';
import { useEffect } from 'react';
import { notification } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

interface INotificationStoreState {
  notification?: NotificationInstance;
  contextHolder?: React.ReactNode;
}

interface INotificationStoreMethods extends NotificationInstance {
  getNotificationInstance: () => NotificationInstance | undefined;
}

interface INotificationHook extends INotificationStoreState, INotificationStoreMethods {}

const defaultState: INotificationStoreState = {};

export const useNotificationStore = create<INotificationStoreState>(() => defaultState);

export const useNotificationStoreMethods: INotificationStoreMethods = {
  success(args) {
    useNotificationStoreMethods.getNotificationInstance()?.success(args);
  },
  error(args) {
    useNotificationStoreMethods.getNotificationInstance()?.error(args);
  },
  info(args) {
    useNotificationStoreMethods.getNotificationInstance()?.info(args);
  },
  warning(args) {
    useNotificationStoreMethods.getNotificationInstance()?.warning(args);
  },
  destroy(key) {
    useNotificationStoreMethods.getNotificationInstance()?.destroy(key);
  },
  open(args) {
    useNotificationStoreMethods.getNotificationInstance()?.open(args);
  },
  getNotificationInstance() {
    return useNotificationStore.getState().notification;
  },
};

export function useNotification(): INotificationHook {
  const [api, contextHolder] = notification.useNotification();
  const storeState = useNotificationStore();

  useEffect(() => {
    useNotificationStore.setState({ notification: api });
  }, [api]);

  return { ...storeState, contextHolder, ...useNotificationStoreMethods };
}
