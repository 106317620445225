import { Stage } from 'konva/lib/Stage';
import { Vector2d } from 'konva/lib/types';

export function getPositionFromCoordinates(stage: Stage, coordinates: Vector2d) {
  if (!stage) return;

  const stageTransform = stage.getAbsoluteTransform().copy();

  if (!coordinates || !stageTransform) return;

  // .point() translates stage point to screen point
  // .invert().point() translates screen point to stage point
  const position = stageTransform.point(coordinates);

  return position;
}
