import { FaPlus } from 'react-icons/fa';
import { Button, Tooltip } from 'antd';
import { useHotkeys } from 'react-hotkeys-hook';
import shallow from 'zustand/shallow';

import { useCanvasEditorStore, useCanvasEditorStoreMethods } from '../../../hooks';

export default function CanvasEditorMaskClear() {
  const { lines } = useCanvasEditorStore((state) => ({ lines: state.lines }), shallow);
  const canClear = lines.length > 0;

  const handleClearMask = () => {
    useCanvasEditorStoreMethods.clearLines();
  };

  // Clear mask
  useHotkeys(
    'shift+c',
    (e: KeyboardEvent) => {
      e.preventDefault();
      handleClearMask();
    },
    {
      // enabled: activeTabName === 'inpainting' && shouldShowMask && !isMaskEmpty,
      enabled: canClear,
    },
    [canClear]
  );
  return (
    <Tooltip placement="right" title="Clear All Edits (C)">
      <Button
        type="default"
        disabled={!canClear}
        icon={<FaPlus style={{ transform: 'rotate(45deg)' }} />}
        size="large"
        onClick={handleClearMask}
      />
    </Tooltip>
  );
}
