import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider, darkTheme, Theme } from '@rainbow-me/rainbowkit';
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { InjectedConnector } from 'wagmi/connectors/injected';
import merge from 'lodash/merge';

import { lightGray, purple, white } from './colors';
import AuthProvider from './AuthProvider';

interface IProps {
  children: React.ReactNode;
}

const { chains, provider } = configureChains([chain.arbitrumGoerli], [publicProvider()]);

const { connectors } = getDefaultWallets({
  appName: 'Incell',
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors: [...connectors(), new InjectedConnector({ chains })],
  provider,
});

const theme: Theme = merge(
  darkTheme({
    accentColor: purple,
    accentColorForeground: white,
    borderRadius: 'small',
    fontStack: 'rounded',
  }),
  {
    fonts: {
      body: 'Helvetica, Arial, sans-serif',
    },
    colors: {
      connectButtonText: lightGray,
    },
    radii: {
      modal: '4px',
      actionButton: '4px',
      connectButton: '4px',
      menuButton: '4px',
      modalMobile: '4px',
    },
  } as Partial<Theme>
);

export default function WalletProvider({ children }: IProps) {
  return (
    <WagmiConfig client={wagmiClient}>
      <AuthProvider>
        <RainbowKitProvider chains={chains} theme={theme}>
          {children}
        </RainbowKitProvider>
      </AuthProvider>
    </WagmiConfig>
  );
}
