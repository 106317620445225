import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import shallow from 'zustand/shallow';
import { Vector2d } from 'konva/lib/types';
import throttle from 'lodash/throttle';

import { useCanvasStore } from '../../hooks';
import { getPositionFromCoordinates } from '../../utils';

interface IProps {
  coords: Vector2d;
  children: React.ReactNode;
}

export default function PositionOnGrid({ coords, children }: IProps) {
  const { stageRef, scale } = useCanvasStore((state) => ({ stageRef: state.stageRef, scale: state.scale }), shallow);
  const [updater, setUpdater] = useState(0);

  const translation = useMemo(() => {
    if (!stageRef.current) return;

    const pos = getPositionFromCoordinates(stageRef.current, coords);

    if (!pos) return;

    return `${pos.x}px,${pos.y}px`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords, stageRef.current, scale, updater]);

  useEffect(() => {
    if (!stageRef.current) return;

    const stage = stageRef.current;

    const updateVal = throttle(() => setUpdater((value) => value + 1), 30);

    stage.on('dragstart', updateVal);
    stage.on('dragmove', updateVal);
    stage.on('dragend', updateVal);

    return () => {
      if (stage) {
        stage.off('dragstart', updateVal);
        stage.off('dragmove', updateVal);
        stage.off('dragend', updateVal);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageRef.current]);

  if (!translation) return null;

  return <Container style={{ transform: `translate(${translation})` }}>{children}</Container>;
}

const Container = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  transition: transform 0.03s linear;
`;
