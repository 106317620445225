import { useEffect, useState } from 'react';
import shallow from 'zustand/shallow';
import random from 'lodash/random';
import { Vector2d } from 'konva/lib/types';

import { calcFocusOnCoords } from '../utils';
import { CELL_SIZE_PX } from '../constants';
import { useGridConfigStore } from './useGridConfig';
import { useLinkToCell } from './useLinkToCell';

export function useInitialScaleAndPos() {
  const { config } = useGridConfigStore((state) => ({ config: state.config }), shallow);
  const { initialized: linkToCellInitialized, scaleAndPos: linkToCellScaleAndPos } = useLinkToCell();
  const [initialized, setInitialized] = useState(false);
  const [scaleAndPos, setScaleAndPos] = useState<{ scale: number; position: Vector2d }>();

  useEffect(() => {
    if (initialized) return;
    if (!linkToCellInitialized) return;
    if (!config) return;
    if (linkToCellScaleAndPos) {
      setScaleAndPos(linkToCellScaleAndPos);
      setInitialized(true);
      return;
    }

    const randomX = random(0, (config.width - 1) * CELL_SIZE_PX, false);
    const randomY = random(0, (config.height - 1) * CELL_SIZE_PX, false);
    const newScaleAndPos = calcFocusOnCoords({ coords: { x: randomX, y: randomY }, padding: CELL_SIZE_PX });
    setScaleAndPos(newScaleAndPos);
    setInitialized(true);
  }, [config, initialized, linkToCellInitialized, linkToCellScaleAndPos]);

  return { initialized, scaleAndPos };
}
