import create from 'zustand';

import { useCanvasStoreMethods } from './useCanvas';
import { useGridStoreMethods } from './useGrid';
import { useNotificationStoreMethods } from './useNotification';
import { handleWsResponse, IGenerationCellResult, useWsConnectionStore } from './useWsConnection';

interface IGenerateImagesStoreState {
  loading: boolean;
  prompt: string;
  overlapSampling: boolean;
  generatedImages: IGenerationCellResult[];
  selectedIndex: number;
}

type GenerateImages = (params: { x: number; y: number; prompt: string; image: string; note: string }) => Promise<void>;

interface IGenerateImagesStoreMethods {
  setPrompt: (prompt: string) => void;
  setOverlapSampling: (overlapSampling: boolean) => void;
  generateImages: GenerateImages;
  selectIndex: (i: number) => void;
  nextImage: () => void;
  prevImage: () => void;
  acceptGeneration: ({ address, url, x, y }: { address: string; url: string; x: number; y: number }) => void;
  cancelGeneration: ({ generationId }: { generationId: string }) => void;
}

interface IGenerateImagesHook extends IGenerateImagesStoreState, IGenerateImagesStoreMethods {}

const defaultState: IGenerateImagesStoreState = {
  loading: false,
  prompt: '',
  overlapSampling: true,
  generatedImages: [],
  selectedIndex: 0,
};

export const useGenerateImagesStore = create<IGenerateImagesStoreState>(() => defaultState);

export const useGenerateImagesStoreMethods: IGenerateImagesStoreMethods = {
  setPrompt: (prompt: string) => {
    useGenerateImagesStore.setState({ prompt });
  },
  setOverlapSampling: (overlapSampling: boolean) => {
    useGenerateImagesStore.setState({ overlapSampling });
  },
  generateImages: ({ x, y, prompt, image, note }) =>
    new Promise((resolve, reject) => {
      const { socket } = useWsConnectionStore.getState();
      console.log('GENERATE IMAGES');

      useGenerateImagesStore.setState({ loading: true });
      socket.emit('generateImages', { x, y, prompt, image, note }, (result) => {
        console.log('GENERATE IMAGES RESPONSE', { result });
        handleWsResponse<IGenerationCellResult[]>(
          result,
          (generatedImages) => {
            useGenerateImagesStore.setState({ generatedImages, selectedIndex: 0, loading: false });
            useCanvasStoreMethods.setMode('pickGeneration');
            useNotificationStoreMethods.success({ message: 'Generated images' });
            resolve();
          },
          () => {
            useGenerateImagesStore.setState({ loading: false });
            reject();
          }
        );
      });
    }),
  selectIndex: (i: number) => {
    useGenerateImagesStore.setState({ selectedIndex: i });
  },
  nextImage: () => {
    const { generatedImages, selectedIndex } = useGenerateImagesStore.getState();
    const numImages = generatedImages.length;
    const newIndex = selectedIndex + 1 >= numImages ? 0 : selectedIndex + 1;

    useGenerateImagesStore.setState({ selectedIndex: newIndex });
  },
  prevImage: () => {
    const { generatedImages, selectedIndex } = useGenerateImagesStore.getState();
    const numImages = generatedImages.length;
    const newIndex = selectedIndex <= 0 ? numImages - 1 : selectedIndex - 1;

    useGenerateImagesStore.setState({ selectedIndex: newIndex });
  },
  acceptGeneration: ({ address, url, x, y }) => {
    const { socket } = useWsConnectionStore.getState();
    console.log('ACCEPT GENERATION');

    useGenerateImagesStore.setState({ loading: true });
    socket.emit('acceptGeneration', { url }, (result) => {
      console.log('ACCEPT GENERATION RESPONSE', result);

      handleWsResponse(
        result,
        () => {
          useGridStoreMethods.upsertCell({
            x,
            y,
            url,
            owner: address,
          });

          useGenerateImagesStore.setState({ generatedImages: [], selectedIndex: 0, loading: false });
          useCanvasStoreMethods.setMode('view');
          useNotificationStoreMethods.success({ message: 'Image saved' });
        },
        () => {
          useGenerateImagesStore.setState({ loading: false });
        }
      );
    });
  },

  cancelGeneration: ({ generationId }: { generationId: string }) => {
    const { socket } = useWsConnectionStore.getState();
    console.log('CANCEL GENERATION');

    useGenerateImagesStore.setState({ loading: true });
    socket.emit('cancelGeneration', { generationId }, (result) => {
      console.log('CANCEL GENERATION RESPONSE', result);

      handleWsResponse(
        result,
        () => {
          useGenerateImagesStore.setState({ generatedImages: [], selectedIndex: 0, loading: false });
          useCanvasStoreMethods.setMode('edit');
        },
        () => {
          useGenerateImagesStore.setState({ loading: false });
        }
      );
    });
  },
};

export function useGenerateImages(): IGenerateImagesHook {
  const storeState = useGenerateImagesStore();

  return {
    ...storeState,
    ...useGenerateImagesStoreMethods,
  };
}
