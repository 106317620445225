import styled from 'styled-components';
import { Button, Form, Input, Switch, Tooltip } from 'antd';
import { useCallback, useState } from 'react';
import shallow from 'zustand/shallow';

import { IGridCellDetails, useCanvasEditorStore, useGenerateImagesStoreMethods, useGridStore } from '../../../hooks';
import { generateSampleFrame } from '../../../utils';
import { FlexSpacer } from '../../common';

interface IProps {
  className?: string;
  cell: IGridCellDetails;
}

const EditMode = ({ cell }: IProps) => {
  const [form] = Form.useForm();
  const { cells, selectedCell } = useGridStore(
    (state) => ({ cells: state.cells, selectedCell: state.selectedCell }),
    shallow
  );
  const { lines } = useCanvasEditorStore((state) => ({ lines: state.lines }), shallow);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      const { prompt, note, overlapSampling } = await form.validateFields();
      const { frameDataURL } = await generateSampleFrame({
        cells,
        lines,
        selectedCell: selectedCell!,
        overlapSampling,
      });
      // downloadFile(frameDataURL);

      console.log('generating...');

      const rawB64 = frameDataURL.replace('data:image/png;base64,', '');
      await useGenerateImagesStoreMethods.generateImages({
        x: selectedCell!.x,
        y: selectedCell!.y,
        prompt,
        image: rawB64,
        note,
      });
    } finally {
      setLoading(false);
    }
  }, [cells, form, lines, selectedCell]);

  return (
    <Container>
      <Form layout="vertical" form={form} name="generate-image">
        <Form.Item name="prompt" label="PROMPT" rules={[{ required: true }]}>
          <Input.TextArea rows={3} showCount maxLength={400} placeholder="I am dreaming of..." disabled={loading} />
        </Form.Item>
        <Form.Item name="note" label="NOTE" initialValue={cell.note || ''}>
          <Input.TextArea
            rows={3}
            showCount
            maxLength={400}
            placeholder="Optional public description (may include links)"
            disabled={loading}
          />
        </Form.Item>
        <Tooltip placement="top" title="Turn off to produce results independent of the adjacent images">
          <Form.Item name="overlapSampling" initialValue={true} label="OVERLAP SAMPLING" labelAlign="left">
            <Switch defaultChecked={true} disabled={loading} />
          </Form.Item>
        </Tooltip>
      </Form>
      <FlexSpacer />
      <div className="footer">
        <hr />
        <span className="description">You have 10 generations left</span>
        <Button type="primary" size="large" onClick={handleSubmit} loading={loading}>
          Generate
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .footer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    hr {
      margin: 0;
    }

    .description {
      text-align: center;
    }
  }
`;

export default EditMode;

function downloadFile(str: string) {
  const link = document.createElement('a');
  document.body.appendChild(link);

  link.href = str;
  link.target = '_self';
  link.download = 'lol' + Date.now() + '.png';
  link.click();
}
