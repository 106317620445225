import { Circle } from 'react-konva';
import shallow from 'zustand/shallow';
import { useCanvasEditorStore, useCanvasStore, useGridConfigStore, useGridStore } from '../../../hooks';

import { getCellFromCursorPosition, isSameCell } from '../../../utils';

/**
 * Draws the canvas brush preview outline.
 */
const CanvasEditorBrushOutline = () => {
  const { scale, cursorPosition, stageRef } = useCanvasStore(
    (state) => ({ scale: state.scale, cursorPosition: state.cursorPosition, stageRef: state.stageRef }),
    shallow
  );
  const { tool, brushSize } = useCanvasEditorStore(
    (state) => ({ tool: state.tool, brushSize: state.brushSize }),
    shallow
  );
  const { selectedCell } = useGridStore((state) => ({ selectedCell: state.selectedCell }), shallow);
  const { config } = useGridConfigStore((state) => ({ config: state.config }), shallow);

  if (tool !== 'eraser') return null;
  if (!cursorPosition) return null;

  const cursorCell = getCellFromCursorPosition(stageRef.current!, config);

  if (!isSameCell(selectedCell, cursorCell)) return null;

  const strokeWidth = 1 / scale;
  const radius = 1 / scale;

  return (
    <>
      <Circle
        x={cursorPosition.x}
        y={cursorPosition.y}
        radius={brushSize / 2}
        stroke={'rgba(0,0,0,1)'}
        strokeWidth={strokeWidth}
        strokeEnabled={true}
        listening={false}
      />
      <Circle x={cursorPosition.x} y={cursorPosition.y} radius={radius} fill={'rgba(0,0,0,1)'} listening={false} />
    </>
  );
};
export default CanvasEditorBrushOutline;
