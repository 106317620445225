import { useMemo } from 'react';
import { Vector2d } from 'konva/lib/types';
import shallow from 'zustand/shallow';

import { useCanvasStore, useGridStore } from '../../../hooks';
import { cellToOffset } from '../../../utils';
import KonvaImageFromUrl from './KonvaImageFromUrl';

/**
 * Draws images around the selected cell to hide any erased areas outside of the bounding box.
 *
 */
const CanvasSurroundingCells = () => {
  const { cells, selectedCell } = useGridStore(
    (state) => ({ cells: state.cells, selectedCell: state.selectedCell }),
    shallow
  );
  const { mode } = useCanvasStore((state) => ({ mode: state.mode }), shallow);
  const surroundingCells = useMemo(() => {
    if (!selectedCell) return [];

    const desiredCells: Vector2d[] = [];

    for (let x = selectedCell.x - 1; x <= selectedCell.x + 1; x++) {
      for (let y = selectedCell.y - 1; y <= selectedCell.y + 1; y++) {
        const isSelected = x === selectedCell.x && y === selectedCell.y;
        if (!isSelected) {
          desiredCells.push({ x, y });
        }
      }
    }

    return cells
      .filter((cell) => !!desiredCells.find((d) => d.x === cell.x && d.y === cell.y))
      .filter((cell) => cell.url);
  }, [cells, selectedCell]);

  if (mode !== 'edit') return null;

  return (
    <>
      {surroundingCells.map((cell) => (
        <KonvaImageFromUrl key={cell.url} listening={false} src={cell.url!} offset={cellToOffset(cell)} />
      ))}
    </>
  );
};

export default CanvasSurroundingCells;
