import { SiweMessage } from '@crowdtainer/siwe';
import { io, Socket } from 'socket.io-client';
import create from 'zustand';

import { API_URL } from '../env';
import { useNotificationStoreMethods } from './useNotification';

interface ServerToClientEvents {
  updateCells: (cells: IGridCellResult[]) => void;
}

interface ClientToServerEvents {
  hello: () => void;
  getGridConfig: (callback: (cfg: IGridConfigResult) => void) => void;
  getCells: (callback: (cells: IGridCellResult[]) => void) => void;
  getCellDetails: (params: { x: number; y: number }, callback: (cell: IGridCellDetails) => void) => void;
  generateImages: (
    params: { x: number; y: number; prompt: string; image: string; note: string },
    callback: (result: IGenerationCellResult[] | IWsError) => void
  ) => void;
  acceptGeneration: (params: { url: string }, callback: (result: IWsError) => void) => void;
  cancelGeneration: (params: { generationId: string }, callback: (result: IWsError) => void) => void;
  updateNote: (params: { x: number; y: number; note: string }, callback: (result: IWsError) => void) => void;
  getAuthNonce: (callback: (nonce: string) => void) => void;
  authWithJwt: (params: { jwt: string }, callback: (result: IWsError) => void) => void;
  authWithSiwe: (
    params: { message: SiweMessage; signature: string },
    callback: (result: { jwt: string } | IWsError) => void
  ) => void;
  signOut: () => void;
}

export interface IGridConfigResult {
  width: number;
  height: number;
}

export interface IGridCellResult {
  x: number;
  y: number;
  url?: string;
  owner: string;
}

export interface IGridCellDetails extends IGridCellResult {
  prompt: string;
  note: string;
}

export interface IGenerationCellResult {
  x: number;
  y: number;
  url: string;
  generationId: string;
}

export interface IWsError {
  error: boolean;
  message?: string;
}

interface IWsConnectionStoreState {
  socket: Socket<ServerToClientEvents, ClientToServerEvents>;
  connected: boolean;
}

interface IWsConnectionStoreMethods {}

interface IWsConnectionHook extends IWsConnectionStoreState, IWsConnectionStoreMethods {}

export const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(API_URL, {});

const defaultState: IWsConnectionStoreState = {
  socket,
  connected: false,
};

export const useWsConnectionStore = create<IWsConnectionStoreState>(() => defaultState);

export function useWsConnection(): IWsConnectionHook {
  const storeState = useWsConnectionStore();

  return storeState;
}

socket.on('connect', (...args) => {
  console.log('connected', args);
  useWsConnectionStore.setState({ connected: true });
});

socket.on('disconnect', (...args) => {
  console.log('disconnected', args);
  useWsConnectionStore.setState({ connected: false });
});

export function handleWsResponse<T>(
  result: any | IWsError,
  successHandler: (res: T) => void,
  errorHandler?: () => void
) {
  if (result.error) {
    console.error(result.message);
    useNotificationStoreMethods.error({ message: result.message });
    if (errorHandler) {
      errorHandler();
    }
    throw new Error(result.message);
  }

  successHandler(result);
}
