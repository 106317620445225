import { createAuthenticationAdapter, RainbowKitAuthenticationProvider } from '@rainbow-me/rainbowkit';
import { useEffect } from 'react';
import { SiweMessage } from '@crowdtainer/siwe';
import { useAccount } from 'wagmi';
import jwtDecode from 'jwt-decode';

import { useAuthStore, useAuthStoreMethods, useWsConnectionStore } from './hooks';
import { IJwt } from './types';

const authenticationAdapter = createAuthenticationAdapter({
  getNonce: async () => {
    return await useAuthStoreMethods.getNonce();
  },

  createMessage: ({ nonce, address, chainId }) => {
    return new SiweMessage({
      domain: window.location.host,
      address,
      statement: 'Sign message to prove you own this address',
      uri: window.location.origin,
      version: '1',
      chainId,
      nonce,
    });
  },

  getMessageBody: ({ message }) => {
    return message.prepareMessage();
  },

  verify: async ({ message, signature }) => {
    await useAuthStoreMethods.authWithSiwe({ message, signature });

    return true;
  },

  signOut: async () => {
    console.log('AUTH SIGN OUT');
    useAuthStoreMethods.signOut();
  },
});

interface IProps {
  children: React.ReactNode;
}

export default function AuthProvider({ children }: IProps) {
  const { address, isConnected } = useAccount();
  const { status, jwt } = useAuthStore();
  const { connected: wsConnected } = useWsConnectionStore();

  useEffect(() => {
    auth();

    async function auth() {
      if (!isConnected || !wsConnected || !address) {
        return;
      }
      if (jwt && (jwtDecode(jwt) as IJwt).address.toLowerCase() === address.toLowerCase()) {
        await useAuthStoreMethods.authWithJwt(jwt);
      } else {
        useAuthStoreMethods.signOut();
      }
    }
  }, [address, isConnected, jwt, wsConnected]);

  return (
    <RainbowKitAuthenticationProvider adapter={authenticationAdapter} status={status}>
      {children}
    </RainbowKitAuthenticationProvider>
  );
}
