// https://github.com/spruceid/siwe/issues/136
import { SiweMessage } from '@crowdtainer/siwe';
import { AuthenticationStatus } from '@rainbow-me/rainbowkit';
import create from 'zustand';
import { persist } from 'zustand/middleware';

import { handleWsResponse, socket } from './useWsConnection';

interface IAuthStoreState {
  status: AuthenticationStatus;
  jwt?: string;
}

interface IAuthStoreMethods {
  getNonce: () => Promise<string>;
  authWithSiwe: ({ message, signature }: { message: SiweMessage; signature: string }) => Promise<{ jwt: string }>;
  authWithJwt: (jwt: string) => Promise<void>;
  signOut: () => void;
}

interface IAuthHook extends IAuthStoreState, IAuthStoreMethods {}

const defaultState: IAuthStoreState = {
  status: 'loading',
};

export const useAuthStore = create<IAuthStoreState>(
  persist(() => defaultState, {
    name: 'auth',
    getStorage: () => window.localStorage,
    partialize: (state) => ({ jwt: state.jwt }),
  }) as any
);

export const useAuthStoreMethods: IAuthStoreMethods = {
  getNonce: () =>
    new Promise((resolve, reject) => {
      console.log('GET NONCE');

      socket.emit('getAuthNonce', (nonce) => {
        console.log('GET NONCE RESPONSE', { nonce });
        resolve(nonce);
      });
    }),
  authWithSiwe: ({ message, signature }) =>
    new Promise((resolve, reject) => {
      console.log('auth with SIWE');

      socket.emit('authWithSiwe', { message, signature }, (result) => {
        console.log('auth with SIWE RESPONSE', result);
        handleWsResponse<{ jwt: string }>(
          result,
          ({ jwt }) => {
            useAuthStore.setState({ status: 'authenticated', jwt });
            resolve({ jwt });
          },
          () => {
            reject((result as any).message!);
          }
        );
      });
    }),
  authWithJwt: async (jwt) =>
    new Promise((resolve, reject) => {
      console.log('auth with JWT');

      socket.emit('authWithJwt', { jwt }, (result) => {
        console.log('auth with SIWE RESPONSE', result);
        handleWsResponse(
          result,
          () => {
            useAuthStore.setState({ status: 'authenticated', jwt });
            resolve();
          },
          () => {
            reject(result.message);
          }
        );
      });
    }),
  signOut: () => {
    useAuthStore.setState({ status: 'unauthenticated', jwt: undefined });
    socket.emit('signOut');
  },
};

export function useAuth(): IAuthHook {
  const storeState = useAuthStore();

  return { ...storeState, ...useAuthStoreMethods };
}
