import create from 'zustand';

import { IGridConfigResult, useWsConnectionStore } from './useWsConnection';

interface IGridConfigStoreState {
  loading: boolean;
  config?: IGridConfigResult;
}

interface IGridConfigStoreMethods {}

interface IGridConfigHook extends IGridConfigStoreState, IGridConfigStoreMethods {}

const defaultState: IGridConfigStoreState = {
  loading: true,
};

export const useGridConfigStore = create<IGridConfigStoreState>(() => defaultState);

export function useGridConfig(): IGridConfigHook {
  const storeState = useGridConfigStore();

  return storeState;
}

useWsConnectionStore.subscribe((state, prevState) => {
  if (state.connected && !prevState.connected) {
    getGridConfig();
  }
});

function getGridConfig() {
  const { socket } = useWsConnectionStore.getState();
  console.log('GET GRID CONFIG');

  socket.emit('getGridConfig', (config) => {
    console.log('GET CONFIG RESPONSE', { config });
    useGridConfigStore.setState({ config, loading: false });
  });
}
