import { ReactComponent as LogoDark } from './incell_logo_dark.svg';
import { ReactComponent as LogoLight } from './incell_logo_light.svg';

interface IProps {
  dark?: boolean;
  [key: string]: any;
}

export default function IncellLogo({ dark, ...rest }: IProps) {
  if (dark) {
    return <LogoDark {...rest} />;
  }

  return <LogoLight {...rest} />;
}
