import { useMemo } from 'react';
import { Vector2d } from 'konva/lib/types';

import { CELL_SIZE_PX } from '../../constants';
import PositionOnGrid from './PositionOnGrid';

interface IProps {
  placement: 'top' | 'bottom' | 'left' | 'right';
  cell: Vector2d;
  children: React.ReactNode;
}

export default function PositionRelativeToCell({ cell, children, placement }: IProps) {
  const coords = useMemo(() => {
    if (!cell) return;

    let x = cell.x * CELL_SIZE_PX;
    let y = cell.y * CELL_SIZE_PX;

    switch (placement) {
      case 'top': {
        x += CELL_SIZE_PX / 2;
        break;
      }
      case 'bottom': {
        x += CELL_SIZE_PX / 2;
        y += CELL_SIZE_PX;
        break;
      }
      case 'left': {
        y += CELL_SIZE_PX / 2;
        break;
      }
      case 'right': {
        x += CELL_SIZE_PX;
        y += CELL_SIZE_PX / 2;
        break;
      }
    }

    return { x, y };
  }, [cell, placement]);

  if (!coords) return null;

  return <PositionOnGrid coords={coords}>{children}</PositionOnGrid>;
}
