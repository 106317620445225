import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import { FaPlus } from 'react-icons/fa';
import cn from 'classnames';
import shallow from 'zustand/shallow';
import { useAccount } from 'wagmi';

import {
  IGridCellDetails,
  useCanvasStore,
  useCanvasStoreMethods,
  useGenerateImagesStore,
  useGridStore,
  useGridStoreMethods,
} from '../../../hooks';
import { SIDEPANEL_WIDTH } from '../../../constants';
import CellHeader from './CellHeader';
import ViewMode from './ViewMode';
import EditMode from './EditMode';

interface IProps {
  className?: string;
  open: boolean;
}

const SidePanel = ({ className, open }: IProps) => {
  const { address } = useAccount();
  const { mode } = useCanvasStore((state) => ({ mode: state.mode }), shallow);
  const { selectedCell: selectedCoords, cells } = useGridStore(
    (state) => ({ selectedCell: state.selectedCell, cells: state.cells }),
    shallow
  );
  const { generatingImages } = useGenerateImagesStore((state) => ({ generatingImages: state.loading }), shallow);
  const [loading, setLoading] = useState(false);
  const [cell, setCell] = useState<IGridCellDetails>();
  const selectedCoordsRef = useRef(selectedCoords);
  selectedCoordsRef.current = selectedCoords;

  const isCellOwner = useMemo(() => {
    return !!(cell?.owner && cell?.owner.toLowerCase() === address?.toLowerCase());
  }, [address, cell?.owner]);

  const selectedCell = useMemo(() => {
    return cells.find((c) => c.x === selectedCoords?.x && c.y === selectedCoords?.y);
  }, [cells, selectedCoords]);

  const getCellDetails = useCallback(async () => {
    console.log('getCellDetails');
    try {
      setLoading(true);
      const res = await useGridStoreMethods.getCellDetails(selectedCoordsRef.current!);
      setCell(res);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (selectedCoords && selectedCell) {
      getCellDetails();
    } else {
      setCell(undefined);
    }
  }, [getCellDetails, selectedCell, selectedCoords]);

  const handleClose = () => {
    useCanvasStoreMethods.setMode('view');
    useGridStoreMethods.setSelectedCell(null);
  };

  return (
    <Container className={cn({ className, open })}>
      <Tooltip placement="left" title="Close (ESC)">
        <Button
          className="closeBtn"
          type="ghost"
          icon={<FaPlus style={{ transform: 'rotate(45deg)' }} />}
          size="large"
          disabled={generatingImages}
          onClick={handleClose}
        />
      </Tooltip>
      <CellHeader
        loading={loading}
        mode={mode}
        selectedCoords={selectedCoords}
        selectedCell={cell}
        isCellOwner={isCellOwner}
      />
      <hr />

      {cell && (
        <>
          {mode === 'view' ? (
            <ViewMode isCellOwner={isCellOwner} cell={cell!} onRefresh={getCellDetails} />
          ) : (
            <EditMode cell={cell!} />
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  width: ${SIDEPANEL_WIDTH}px;
  height: 100vh;
  background-color: var(--bg);
  border-left: 1px solid var(--surfaceVariant);
  transform: translateX(100%);
  transition: transform 0.2s ease;
  padding: 32px;
  padding-top: 104px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &.open {
    transform: translateX(0);
  }

  .closeBtn {
    position: absolute;
    top: 88px;
    left: 20px;
    opacity: 0.3;
  }

  hr {
    margin: 32px 0;
  }
`;

export default SidePanel;
