import create from 'zustand';
import uniqBy from 'lodash/uniqBy';
import { Vector2d } from 'konva/lib/types';

import { handleWsResponse, IGridCellDetails, IGridCellResult, socket, useWsConnectionStore } from './useWsConnection';
import { useNotificationStoreMethods } from './useNotification';

interface IGridStoreState {
  loading: boolean;
  cells: IGridCellResult[];
  selectedCell: Vector2d | null;
  highlightOwnCells: boolean;
}

interface IGridStoreMethods {
  setSelectedCell: (cell: Vector2d | null) => void;
  getCells: () => void;
  getCellDetails: (coords: Vector2d) => Promise<IGridCellDetails>;
  updateNote: (cell: Vector2d, note: string) => Promise<void>;
  upsertCell: (cell: IGridCellResult) => void;
  upsertCells: (cells: IGridCellResult[]) => void;
  toggleHighlightOwnCells: () => void;
}

interface IGridHook extends IGridStoreState, IGridStoreMethods {}

const defaultState: IGridStoreState = {
  loading: true,
  cells: [],
  selectedCell: null,
  highlightOwnCells: false,
};

export const useGridStore = create<IGridStoreState>(() => defaultState);

export const useGridStoreMethods: IGridStoreMethods = {
  setSelectedCell: (selectedCell: Vector2d | null) => {
    useGridStore.setState({ selectedCell });
  },
  getCells: () => {
    const { socket } = useWsConnectionStore.getState();
    console.log('GET CELLS');

    socket.emit('getCells', (cells) => {
      console.log('GET CELLS RESPONSE', { cells });
      useGridStore.setState({ cells, loading: false });
    });
  },
  getCellDetails: async ({ x, y }) =>
    new Promise((resolve, reject) => {
      console.log('getCellDetails');

      socket.emit('getCellDetails', { x, y }, (result) => {
        console.log('getCellDetails RESPONSE', result);
        handleWsResponse<IGridCellDetails>(
          result,
          (details) => {
            resolve(details);
          },
          () => {
            reject((result as any).message!);
          }
        );
      });
    }),
  updateNote: async ({ x, y }, note) =>
    new Promise((resolve, reject) => {
      console.log('updateNote');

      socket.emit('updateNote', { x, y, note }, (result) => {
        console.log('updateNote RESPONSE', result);
        handleWsResponse<IGridCellDetails>(
          result,
          () => {
            useNotificationStoreMethods.success({ message: 'Note updated' });
            resolve();
          },
          () => {
            reject((result as any).message!);
          }
        );
      });
    }),
  upsertCell: (cell: IGridCellResult) => {
    useGridStoreMethods.upsertCells([cell]);
  },
  upsertCells: (updatedCells: IGridCellResult[]) => {
    const { cells } = useGridStore.getState();

    const newCells = uniqBy([...updatedCells, ...cells], (c) => `${c.x}_${c.y}`);

    console.log({ cells, newCells });

    useGridStore.setState({ cells: newCells });
  },
  toggleHighlightOwnCells: () => {
    useGridStore.setState((state) => ({ highlightOwnCells: !state.highlightOwnCells }));
  },
};

export function useGrid(): IGridHook {
  const storeState = useGridStore();

  return { ...storeState, ...useGridStoreMethods };
}

useWsConnectionStore.subscribe((state, prevState) => {
  if (state.connected && !prevState.connected) {
    useGridStoreMethods.getCells();
  }
});

socket.on('updateCells', (cells: IGridCellResult[]) => {
  console.log('updateCells', cells);
  useGridStoreMethods.upsertCells(cells);
});
