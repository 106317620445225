import { useEffect, useState } from 'react';
import { Image as KonvaImage } from 'react-konva';

interface IProps {
  src: string;
  [key: string]: any;
}

const KonvaImageFromUrl = ({ src, ...rest }: IProps) => {
  const [image, setImage] = useState<HTMLImageElement>();

  useEffect(() => {
    const imageElem = new Image();
    imageElem.crossOrigin = 'anonymous';
    imageElem.src = src;
    setImage(imageElem);
  }, [src]);

  return <KonvaImage image={image} listening={false} {...rest} />;
};

export default KonvaImageFromUrl;
