import styled from 'styled-components';
import { ConnectButton } from '@rainbow-me/rainbowkit';

import { HEADER_HEIGHT } from '../../constants';
import { FlexSpacer, IncellLogo, TroveLogo } from '../common';

const SiteHeader = () => {
  return (
    <Container>
      <IncellLogo className="incellLogo" />

      <FlexSpacer />
      <div className="troveLink">
        <TroveLogo className="troveLogo" />
        Marketplace
      </div>
      <ConnectButton showBalance={false} />
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: ${HEADER_HEIGHT}px;
  background: var(--surface);
  border-bottom: 1px solid var(--surfaceVariant);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 32px;

  .incellLogo {
    height: 50px;
  }

  .troveLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 48px;
    cursor: pointer;
  }

  .troveLogo {
    width: 24px;
    margin-right: 10px;
  }
`;

export default SiteHeader;
